<template>
  <div>
    <v-app-bar color="primary" dark fixed>
      <v-container class="d-flex align-center pa-0">
        <v-btn class="mr-3" small icon dark @click="$emit('back')">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <v-toolbar-title class="mt-1">
          <div>
            <h1 class="title font-weight-bold mb-1 title" style="line-height: 1">Dados Dependentes</h1>
            <h3 class="caption ma-0" style="line-height: 1">Dados contratação</h3>
          </div>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn small icon dark @click="$emit('openmenu')">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </v-container>
    </v-app-bar>
    <div class="header-height"></div>
    <v-container class="pa-5 pt-0">
      <div class="d-flex justify-center mt-10 pa-10" v-if="loading">
        <v-progress-circular indeterminate color="grey" size="50"></v-progress-circular>
      </div>
      <v-row v-else justify="center">
        <v-col class="col-12 col-md-6">
          <div class="d-flex justify-center mb-4 mt-2">
            <div
              class="caption text-center"
            >A alteração de dependente pode<br/>influenciar no valor total do plano</div>
          </div>
          <v-sheet
            v-for="(dependente, index) in dependentes"
            :key="dependente.id"
            class="d-flex align-center mb-5"
            elevation="2"
            style="overflow: hidden; cursor: pointer"
          >
            <div
              v-ripple
              class="pa-4 d-flex"
              style="flex: 1; overflow: hidden"
              @click="selecionarDependente(dependente, index)"
            >
              <div class="col-9 pa-0">
                <div
                  class="text-truncate subtitle-2 font-weight-regular"
                >{{dependente.nome ? dependente.nome : formatDate(dependente.dataNascimento) }}</div>
                <div
                  class="text-truncate subtitle-2 font-weight-regular grey--text"
                >{{dependente.nome ? formatDate(dependente.dataNascimento) + ' - ' : ''}} {{ idade(dependente.dataNascimento)}}</div>
              </div>
              <v-spacer></v-spacer>
              <v-icon v-if="!dependente.check" size="30" color="rgba(0,0,0,.4)">mdi-alert-circle</v-icon>
              <v-icon v-else size="30" color="success">mdi-check-circle</v-icon>
            </div>
            <v-btn icon color="primary" class="mr-4 ml-4 secundaryBtn--text" v-if="!desabilitaRemove" @click="removeDependente(dependente, index)">
              <v-icon size="34">mdi-delete</v-icon>
            </v-btn>
          </v-sheet>

          <dialog-dependente
            ref="refDialogDependente"
            @saveData="handleDependenteData"
            :dependente="dependenteSelected"
            v-model="dialogDependente"
            v-if="dialogDependente"
          ></dialog-dependente>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col class="d-flex justify-center">
          <v-dialog v-model="dialog" max-width="300" @input="clearDate" eager>
            <template v-slot:activator="{on}">
              <v-btn :data-test-id="'adicionar-dependente'" v-on="on" color="primary" text v-if="!desabilitaInput">
                <span class="text-capitalize subtitle-1 font-weight-bold">Adicionar dependente</span>
                <v-icon size="20" class="ml-2">mdi-plus-circle</v-icon>
              </v-btn>
            </template>
            <v-card class="pa-0">
              <v-sheet
                color="primary pa-2"
                style="border-bottom-right-radius: 0; border-bottom-left-radius: 0"
              >
                <h2 class="white--text title text-center">Adicionar dependente</h2>
              </v-sheet>
              <validation-observer tag="form" ref="form" @submit.prevent="addDependente">
                <v-sheet class="pa-4 pt-6 pb-0">
                  <base-text-field
                    rules="required|date"
                    id="dataNascimento"
                    :data-test-id="'dob-dependente-dialogo'"
                    name="Data de nascimento"
                    label="Data de nascimento"
                    placeholder="DD/MM/AAAA"
                    v-mask="'##/##/####'"
                    v-model="date"
                    inputmode="numeric"
                    clearable
                    outlined
                  ></base-text-field>
                </v-sheet>
                <v-divider></v-divider>
                <v-sheet color="pa-2" class="d-flex">
                  <v-btn :data-test-id="'cancelar-dialogo'" text color="primary" class="secundaryBtn--text" @click="dialog = false">
                    <span class="font-weight-bold text-capitalize subtitle-1">Cancelar</span>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn :data-test-id="'confirmar-dialogo'" :loading="addingDependente" text color="primary" class="secundaryBtn--text" type="submit">
                    <span class="font-weight-bold text-capitalize subtitle-1">Confirmar</span>
                  </v-btn>
                </v-sheet>
              </validation-observer>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row justify="center" no-gutters>
        <v-col class="col-12 col-md-6">
          <v-btn
            :disabled="!isValid"
            @click="submit"
            :loading="loadingSalvar"
            large
            :data-test-id="'salvar-continuar-6'"
            type="submit"
            elevation="10"
            block
            color="primary"
            class="secundaryBtn--text mt-10 mb-10"
          >Salvar e continuar</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import filters from '@/filters';
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import { dependenteFactory } from '@/utils/factories/dependenteFactory';
import { getPlanoSimilar } from '@/mixins/getPlanoSimilar';
import propostaService from '@/services/propostaService';
import BaseTextField from '@/components/BaseTextField.vue';
import DialogDependente from './DialogDependente.vue';

export default {
  name: 'DadosDependentes',
  components: { BaseTextField, DialogDependente },
  data() {
    return {
      loadingSalvar: false,
      addingDependente: false,
      dialogDependente: false,
      dependentesBeforeAdd: [],
      dependenteSelected: {},
      dialog: false,
      date: null,
      loading: false,
      dependentes: [],
    };
  },
  mixins: [getPlanoSimilar],
  computed: {
    ...mapState({
      dependentesState: (state) => state.cadastroProposta.dependentes,
      propostaState: (state) => state.cadastroProposta.proposta,
      propostaStatePME: (state) => state.PME,
      etapaBeforeReset: (state) => state.cadastroProposta.etapaBeforeReset,
      blnContinuarState: (state) => state.cadastroProposta.blnContinuar,
    }),
    isValid() {
      const index = this.dependentes.findIndex((dep) => !dep.check);
      return index === -1;
    },
    desabilitaInput() {
      return (this.propostaState.status || '') !== 'EM_DIGITACAO';
    },
    desabilitaRemove() {
      return (this.propostaState.status || '') !== 'EM_DIGITACAO';
    },
  },
  mounted() {
    this.validaCheck();
  },
  watch: {
    dependentesState: {
      immediate: true,
      handler: 'setData',
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      setDadosDependentes: 'cadastroProposta/setDadosDependentes',
      setDadosProposta: 'cadastroProposta/setDadosProposta',
      setBlnContinuar: 'cadastroProposta/setBlnContinuar',
      setProdutosOpcionais: 'cadastroProposta/setProdutosOpcionais',
    }),
    validaCheck() {
      this.dependentes.forEach((item, index) => {
        if ((filters.getAge(new Date(item.dataNascimento)) < 8 || item.cpf) && item.dataNascimento && item.estadoCivil
          && item.nome && item.nomeMae && item.parentesco && item.sexo) {
          this.$set(this.dependentes[index], 'check', true);
        }
      });
    },
    idade(data) {
      return `${filters.getAge(data)} anos`;
    },
    async handleDependenteData({ dependente, index }) {
      /* const index = this.dependentes.findIndex(
        (dep) => dep.guid === dependente.id || dep.guid === dependente.guid,
      );
      if (index === -1) return; */
      this.$set(this.dependentes, index, dependente);
      this.$set(this.dependentes[index], 'check', true);
      this.setDadosDependentes(this.dependentes);
      try {
        this.loading = true;
        await this.planoAlterado(this.propostaStatePME.dataVigencia);
        if (this.confirmaAlteracao) {
          this.dialog = false;
        } else {
          this.setDadosDependentes(this.dependentesBeforeAdd);
        }
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: error.message || "Erro ao atualizar dependente, tente novamente mais tarde",
        });
      } finally {
        this.loading = false;
      }
    },
    setData() {
      if (!this.dependentesState || !this.dependentesState.length) return;
      const dependentes = this.$cleanObserver(this.dependentesState);
      this.dependentes = [...dependentes];
    },
    selecionarDependente(dependente, index) {
      this.dependenteSelected = { dependente, index };
      this.dialogDependente = true;
    },
    clearDate() {
      this.date = '';
      this.$refs.form.reset();
    },
    async addDependente() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;
      if (this.enabledAddDependente()) {
        this.addingDependente = true;
        const dependentesBeforeAdd = this.$cleanObserver(this.dependentes);
        this.dependentesBeforeAdd = dependentesBeforeAdd;
        const dependentesNascimento = this.dependentes.map((d) => d.dataNascimento);
        dependentesNascimento.push(moment(this.date, 'DD/MM/YYYY').format('YYYY-MM-DD'));
        this.dependentes.push(dependenteFactory({ dataNascimento: this.date }));
        this.setDadosDependentes(this.dependentes);

        await this.planoAlterado(this.propostaStatePME.vigencia);

        if (this.confirmaAlteracao) {
          this.dialog = false;
        } else {
          this.setDadosDependentes(this.dependentesBeforeAdd);
        }
        this.addingDependente = false;
      }
    },
    enabledAddDependente() {
      return true
    },
    async removeDependente(dependente, index) {
      try {
        // Verifica obrigatoriedade na remoção de dependentes
        const { entidade } = this.propostaState.titular;
        const { operadora } = this.propostaState.plano;
        const { dependenteObrigatorio } = await propostaService.verificaDependenteObrigatorio(entidade, operadora);

        if (dependenteObrigatorio && this.dependentes.length === 1) {
          this.$root.$snackBar.open({
            color: "warning",
            message: "Para essa operadora e entidade é obrigatório ao menos um dependente",
          });
          return;
        }

        this.loading = true;
        const dependentesBeforeRemove = this.$cleanObserver(this.dependentes);
        this.dependentes.splice(index, 1);
        this.setDadosDependentes(this.dependentes);
        await this.planoAlterado(this.propostaState.dataVigencia);
        if (!this.confirmaAlteracao) {
          this.setDadosDependentes(dependentesBeforeRemove);
          return;
        }
        const resp = await this.$root.$confirmdialog.open(
          'Remover Dependente',
          'Tem certeza que deseja remover esse dependente?',
        );
        if (!resp) {
          this.setDadosDependentes(dependentesBeforeRemove);
          return;
        }
        if (dependente.guid) await this.removeDependenteBanco(dependente.guid);
      // eslint-disable-next-line no-empty
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: error.message || "Erro ao remover dependente, tente novamente mais tarde",
        });
      } finally {
        this.loading = false;
      }
    },
    async removeDependenteBanco(guid) {
      try {
        const payload = {
          dependentes: [{ guid }],
        };
        await propostaService.removeDados({
          guidProposta: this.propostaState.guid,
          payload,
        });
      } catch (error) {
        this.$root.$snackBar.open({
          color: 'error',
          message: error.message,
        });
      }
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    getAnosIdade(dataNascimento, dataComparada) {
      const arrayNascimento = dataNascimento.split("-")
      const arrayComparada = dataComparada.split("-")
      const dataInicio = new Date(arrayNascimento[0], arrayNascimento[1], arrayNascimento[2])
      const dataFim = new Date(arrayComparada[0], arrayComparada[1], arrayComparada[2])
      let idade = dataFim.getFullYear() - dataInicio.getFullYear()
      const verificaSeNaoPassouMesAniversario = dataInicio.getMonth() > dataFim.getMonth()
      if (verificaSeNaoPassouMesAniversario) {
        return idade -= 1
      }
      const verificaSeNaoPassouDiaAniversario = dataInicio.getDate() > dataFim.getDate()
      if (verificaSeNaoPassouDiaAniversario && dataInicio.getMonth() === dataFim.getMonth() ) {
        return idade -= 1
      }
      return idade
    },
    async submit() {
      // Verifica obrigatoriedade na inclusão de dependentes
      const { entidade } = this.propostaState.titular;
      const { operadora } = this.propostaState.plano;
      const { dependenteObrigatorio } = await propostaService.verificaDependenteObrigatorio(entidade, operadora);

      const idadeNaDataVigencia = this.getAnosIdade(this.propostaState.titular.dataNascimento, this.propostaState.vigencia.dataVigencia || this.propostaStatePME.vigencia)
      if(operadora === "Klini" && idadeNaDataVigencia < 12 && !this.propostaState.plano.coparticipacao) {
        if (this.dependentes.length === 0) {
          this.$root.$snackBar.open({
            color: 'error',
            message: "Para seguir com esta venda, é obrigatório incluir ao menos um dependente maior de 18 anos",
          });
          return;
        } else {
          const verificaExisteUmDependenteMaiorDeIdade = this.dependentes.filter(dep => this.getAnosIdade(dep.dataNascimento, this.propostaState.vigencia.dataVigencia) >= 18).length > 0
          if (!verificaExisteUmDependenteMaiorDeIdade) {
            this.$root.$snackBar.open({
              color: 'error',
              message: "Para seguir com esta venda, é obrigatório incluir ao menos um dependente maior de 18 anos",
            });
            return
          }
        }
      }
      if (dependenteObrigatorio && !this.dependentes.length) {
        this.$root.$snackBar.open({
          color: "warning",
          message: "Para essa operadora e entidade é obrigatória a inclusão de ao menos um dependente",
        });
        return;
      }

      const cpfsDuplicados = this.verificarCPFDep(this.dependentes);
      const cpfEqualsTitular = this.verificarCPFTitular(this.dependentes);
      // let paginaEspecifica;

      if (cpfsDuplicados || cpfEqualsTitular) {
        if (cpfsDuplicados) {
          this.$root.$snackBar.open({
            color: 'error',
            message: "Dependentes possuem CPF's iguais, revise os dados antes de prosseguir!",
          });
        }

        if (cpfEqualsTitular) {
          this.$root.$snackBar.open({
            color: 'error',
            message: 'Dependentes possuem CPF com o mesmo número do titular, revise os dados antes de prosseguir!',
          });
        }
      } else {
        // if ((this.blnContinuarState.isEditing || this.blnContinuarState.isEditingVigencia)
        // && this.propostaState.plano && this.propostaState.plano.possuiPlanosOpcionais) {
        //   paginaEspecifica = { specificStep: 11 };
        //   this.setDadosProposta({
        //     ...this.propostaState,
        //     etapa: 'produto_opcional',
        //   });
        // } else if (this.blnContinuarState.isEditing || this.blnContinuarState.isEditingVigencia) {
        //   const isNotDevolvida = this.propostaState.status !== "DEVOLVIDA";
        //   paginaEspecifica = { specificStep: 12 };

        //   this.setDadosProposta({
        //     ...this.propostaState,
        //     etapa: this.blnContinuarState.isEditingVigencia && isNotDevolvida ? 'dados_financeiros' : undefined,
        //   });
        // } else if (this.propostaState.possuiPortabilidade && this.propostaState.portabilidadePorBeneficiario) {
        //   this.setDadosProposta({
        //     ...this.propostaState,
        //     etapa: 'dados_portabilidade',
        //   });
        // } else if (this.propostaState.plano && this.propostaState.plano.possuiPlanosOpcionais) {
        //   this.setDadosProposta({
        //     ...this.propostaState,
        //     etapa: 'produto_opcional',
        //   });
        // } else {
        //   this.setDadosProposta({
        //     ...this.propostaState,
        //     etapa: 'dados_financeiros',
        //   });
        // }

        try {
          this.loadingSalvar = true;
          this.$emit("atualizarEtapa");
          await this.salvarDados();
          this.$emit('next');
        } catch (error) {
          this.$root.$snackBar.open({
            color: 'error',
            message: error.message || 'Erro ao salvar dados, tente novamente mais tarde',
          });
        } finally {
          this.loadingSalvar = false;
        }
      }
    },
    async salvarDados() {
      const [data] = await propostaService.addOrEdit(this.propostaState.guid, this.propostaState);

      if (data && data.dependentes && data.dependentes.length) {
        const deps = data.dependentes.map(({ parentesco, ...resto }) => ({
          ...resto,
          parentesco: parentesco.nome,
        }));
        this.setDadosDependentes(deps);
      }

      this.setProdutosOpcionais(data.produtosOpcionais.map((item) => item.idProdutoFatura));
    },
    verificarCPFDep(dependentes) {
      /* PEGAR TODOS OS CPFS DOS DEPENDENTES DO ARRAY */
      const valuesCPFs = dependentes.map((item) => filters.removeSymbols(item.cpf)).filter((dep) => dep);
      /* VERIFICAR SE EXISTE ALGUM CPF DUPLICADO */
      const validCPF = valuesCPFs.some((item, ind) => valuesCPFs.indexOf(item) !== ind);
      return validCPF;
    },
    verificarCPFTitular(dependentes) {
      /* PEGAR TODOS OS CPFS DOS DEPENDENTES DO ARRAY */
      const valuesCPFs = dependentes.map((item) => filters.removeSymbols(item.cpf));
      /* VERIFICAR SE EXISTE ALGUM CPF IGUAL AO DO TITULAR */
      const validCPF = valuesCPFs.some((item, ind) => item === this.propostaState.titular.cpf);
      return validCPF;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-height {
  padding: 20px;
  height: 70px;
}
</style>
