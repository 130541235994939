var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { color: "primary", dark: "", fixed: "" } },
        [
          _c(
            "v-container",
            { staticClass: "d-flex align-center pa-0" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-3",
                  attrs: { small: "", icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("back")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                1
              ),
              _c("v-toolbar-title", { staticClass: "mt-1" }, [
                _c("div", [
                  _c(
                    "h1",
                    {
                      staticClass: "title font-weight-bold mb-1 title",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v("Dados titular")]
                  ),
                  _c(
                    "h3",
                    {
                      staticClass: "caption ma-0",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v("Dados contratação")]
                  ),
                ]),
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { small: "", icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("openmenu")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-menu")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "header-height" }),
      _c(
        "v-container",
        { staticClass: "pa-5 pt-0" },
        [
          _vm.loading
            ? _c(
                "div",
                { staticClass: "d-flex justify-center mt-10 pa-10" },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", color: "grey", size: "50" },
                  }),
                ],
                1
              )
            : _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "col-12 col-md-6" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("div", { staticClass: "d-flex justify-end" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "caption font-weight-bold primary--text",
                                  },
                                  [_vm._v("* Campos obrigatórios")]
                                ),
                              ]),
                              _c(
                                "validation-observer",
                                {
                                  ref: "form",
                                  attrs: { tag: "form", autocomplete: "off" },
                                  on: {
                                    submit: function ($event) {
                                      $event.preventDefault()
                                      return _vm.submit.apply(null, arguments)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-expansion-panels",
                                    {
                                      staticClass: "accordion",
                                      attrs: {
                                        tile: "",
                                        flat: "",
                                        multiple: "",
                                      },
                                    },
                                    [
                                      _c("validation-observer", {
                                        ref: "dadosTitular",
                                        attrs: { slim: "" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ valid, failed }) {
                                              return [
                                                _c(
                                                  "v-expansion-panel",
                                                  [
                                                    _c(
                                                      "v-expansion-panel-header",
                                                      {
                                                        staticClass:
                                                          "primary--text subtitle-1 font-weight-bold pa-0 pt-4 pb-3",
                                                        staticStyle: {
                                                          "min-height": "auto",
                                                          "justify-content":
                                                            "space-between",
                                                        },
                                                        attrs: {
                                                          "data-test-id":
                                                            "expandir-dados-titular",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "actions",
                                                              fn: function () {
                                                                return [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        color:
                                                                          "primary",
                                                                        size: "30",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "$expand"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                              proxy: true,
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex align-center",
                                                            attrs: {
                                                              "data-test-id":
                                                                "expandir-dados-titular",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Dados titular "
                                                            ),
                                                            _c("v-spacer"),
                                                            valid
                                                              ? _c(
                                                                  "v-icon",
                                                                  {
                                                                    staticClass:
                                                                      "mr-2",
                                                                    attrs: {
                                                                      color:
                                                                        "green",
                                                                      size: "25",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-check-circle"
                                                                    ),
                                                                  ]
                                                                )
                                                              : failed
                                                              ? _c(
                                                                  "v-icon",
                                                                  {
                                                                    staticClass:
                                                                      "mr-2",
                                                                    attrs: {
                                                                      color:
                                                                        "red",
                                                                      size: "25",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-alert-circle"
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                    _c("v-divider"),
                                                    _c(
                                                      "v-expansion-panel-content",
                                                      {
                                                        staticClass: "pt-4",
                                                        attrs: { eager: "" },
                                                      },
                                                      [
                                                        _c("base-text-field", {
                                                          attrs: {
                                                            "data-test-id":
                                                              "nome-completo",
                                                            mode: "aggressive",
                                                            rules:
                                                              "required|special_characters",
                                                            id: "nome",
                                                            name: "Nome completo",
                                                            label:
                                                              "Nome completo *",
                                                            disabled:
                                                              _vm.desabilitaInput,
                                                            clearable: "",
                                                            outlined: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.form.nome,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.form,
                                                                "nome",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "form.nome",
                                                          },
                                                        }),
                                                        _c("base-text-field", {
                                                          directives: [
                                                            {
                                                              name: "mask",
                                                              rawName: "v-mask",
                                                              value:
                                                                "###.###.###-##",
                                                              expression:
                                                                "'###.###.###-##'",
                                                            },
                                                          ],
                                                          ref: "cpf",
                                                          attrs: {
                                                            rules:
                                                              _vm.validacaoCPFTitular(
                                                                _vm.form.cpf
                                                              ),
                                                            "data-test-id":
                                                              "valida-cpf",
                                                            id: "cpf",
                                                            inputmode:
                                                              "numeric",
                                                            name: "CPF",
                                                            label: "CPF *",
                                                            disabled:
                                                              _vm.desabilitaInput,
                                                            clearable: "",
                                                            outlined: "",
                                                          },
                                                          on: {
                                                            blur: function (
                                                              $event
                                                            ) {
                                                              return _vm.setObrigatoriedadePis()
                                                            },
                                                          },
                                                          model: {
                                                            value: _vm.form.cpf,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.form,
                                                                "cpf",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "form.cpf",
                                                          },
                                                        }),
                                                        _c("base-text-field", {
                                                          directives: [
                                                            {
                                                              name: "mask",
                                                              rawName: "v-mask",
                                                              value:
                                                                "##/##/####",
                                                              expression:
                                                                "'##/##/####'",
                                                            },
                                                          ],
                                                          attrs: {
                                                            type: "text",
                                                            "data-test-id":
                                                              "valida-dob",
                                                            inputmode:
                                                              "numeric",
                                                            rules: "required",
                                                            id: "nascimento",
                                                            name: "Data de nascimento",
                                                            label:
                                                              "Data de nascimento *",
                                                            disabled:
                                                              _vm.desabilitaInput,
                                                            clearable: "",
                                                            outlined: "",
                                                          },
                                                          on: {
                                                            input: _vm.setIdade,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.form
                                                                .dataNascimento,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.form,
                                                                "dataNascimento",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "form.dataNascimento",
                                                          },
                                                        }),
                                                        _c("base-text-field", {
                                                          attrs: {
                                                            mode: "aggressive",
                                                            "data-test-id":
                                                              "nome-mae",
                                                            rules:
                                                              "required|special_characters",
                                                            id: "nomeMae",
                                                            name: "Nome da mãe",
                                                            label:
                                                              "Nome da mãe *",
                                                            clearable: "",
                                                            outlined: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.form.nomeMae,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.form,
                                                                "nomeMae",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "form.nomeMae",
                                                          },
                                                        }),
                                                        _c("base-text-field", {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                _vm.idade >= 18,
                                                              expression:
                                                                "idade >= 18",
                                                            },
                                                          ],
                                                          attrs: {
                                                            type: "email",
                                                            "data-test-id":
                                                              "valida-email",
                                                            mode: "eager",
                                                            rules:
                                                              _vm.idade >= 18
                                                                ? "required|email"
                                                                : "email",
                                                            id: "email",
                                                            name: "E-mail",
                                                            label: `E-mail ${
                                                              _vm.idade >= 18
                                                                ? "*"
                                                                : ""
                                                            }`,
                                                            disabled:
                                                              _vm.desabilitaInput,
                                                            clearable: "",
                                                            outlined: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.form.email,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.form,
                                                                "email",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "form.email",
                                                          },
                                                        }),
                                                        _c("async-tipo-sexo", {
                                                          key:
                                                            "c_tipo-sexo-" +
                                                            _vm.keyTipoSexo,
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function ({
                                                                  loading:
                                                                    loadingTipoSexo,
                                                                  data,
                                                                }) {
                                                                  return [
                                                                    !loadingTipoSexo
                                                                      ? _c(
                                                                          "base-wrapper-dialog-options",
                                                                          {
                                                                            key:
                                                                              "d_tipo-sexo-" +
                                                                              _vm.keyTipoSexo,
                                                                            ref: "dialogSexo",
                                                                            attrs:
                                                                              {
                                                                                headerTitle:
                                                                                  "Sexo",
                                                                                options:
                                                                                  data,
                                                                                "value-key":
                                                                                  "id",
                                                                                "label-key":
                                                                                  "nome",
                                                                                optionSelected:
                                                                                  _vm
                                                                                    .form
                                                                                    .sexo,
                                                                                loading:
                                                                                  loadingTipoSexo,
                                                                              },
                                                                            on: {
                                                                              select:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.$set(
                                                                                    _vm.form,
                                                                                    "sexo",
                                                                                    $event.id
                                                                                  )
                                                                                },
                                                                            },
                                                                            scopedSlots:
                                                                              _vm._u(
                                                                                [
                                                                                  {
                                                                                    key: "activador",
                                                                                    fn: function ({
                                                                                      on,
                                                                                    }) {
                                                                                      return [
                                                                                        _c(
                                                                                          "base-text-field",
                                                                                          _vm._g(
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  readonly:
                                                                                                    "",
                                                                                                  rules:
                                                                                                    "required",
                                                                                                  placeholder:
                                                                                                    "Clique aqui para selecionar",
                                                                                                  id: "sexo",
                                                                                                  name: "Sexo",
                                                                                                  "data-test-id":
                                                                                                    "selecionar-sexo",
                                                                                                  value:
                                                                                                    _vm
                                                                                                      .form
                                                                                                      .sexo,
                                                                                                  label:
                                                                                                    "Sexo *",
                                                                                                  outlined:
                                                                                                    "",
                                                                                                  "append-icon":
                                                                                                    "mdi-chevron-right",
                                                                                                  loading:
                                                                                                    loadingTipoSexo,
                                                                                                },
                                                                                            },
                                                                                            on
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    },
                                                                                  },
                                                                                ],
                                                                                null,
                                                                                true
                                                                              ),
                                                                          }
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }),
                                                        _c(
                                                          "async-estado-civil",
                                                          {
                                                            key:
                                                              "c_estado-civil-" +
                                                              _vm.keyEstadoCivil,
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "default",
                                                                  fn: function ({
                                                                    loading:
                                                                      loadingEstadoCivil,
                                                                    data,
                                                                  }) {
                                                                    return [
                                                                      !loadingEstadoCivil
                                                                        ? _c(
                                                                            "base-wrapper-dialog-options",
                                                                            {
                                                                              key:
                                                                                "d_estado-civil-" +
                                                                                _vm.keyEstadoCivil,
                                                                              ref: "dialogEstadoCivil",
                                                                              attrs:
                                                                                {
                                                                                  headerTitle:
                                                                                    "Estado civil",
                                                                                  options:
                                                                                    data,
                                                                                  "value-key":
                                                                                    "id",
                                                                                  "label-key":
                                                                                    "nome",
                                                                                  optionSelected:
                                                                                    _vm
                                                                                      .form
                                                                                      .estadoCivil,
                                                                                },
                                                                              on: {
                                                                                select:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.$set(
                                                                                      _vm.form,
                                                                                      "estadoCivil",
                                                                                      $event.id
                                                                                    )
                                                                                  },
                                                                              },
                                                                              scopedSlots:
                                                                                _vm._u(
                                                                                  [
                                                                                    {
                                                                                      key: "activador",
                                                                                      fn: function ({
                                                                                        on,
                                                                                      }) {
                                                                                        return [
                                                                                          _c(
                                                                                            "base-text-field",
                                                                                            _vm._g(
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    loading:
                                                                                                      loadingEstadoCivil,
                                                                                                    rules:
                                                                                                      "required",
                                                                                                    readonly:
                                                                                                      "",
                                                                                                    id: "estadoCivil",
                                                                                                    placeholder:
                                                                                                      "Clique aqui para selecionar",
                                                                                                    "data-test-id":
                                                                                                      "selecionar-estado-civil",
                                                                                                    name: "Estado civil",
                                                                                                    value:
                                                                                                      _vm
                                                                                                        .form
                                                                                                        .estadoCivil,
                                                                                                    label:
                                                                                                      "Estado civil *",
                                                                                                    outlined:
                                                                                                      "",
                                                                                                    "append-icon":
                                                                                                      "mdi-chevron-right",
                                                                                                  },
                                                                                              },
                                                                                              on
                                                                                            )
                                                                                          ),
                                                                                        ]
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                                  null,
                                                                                  true
                                                                                ),
                                                                            }
                                                                          )
                                                                        : _vm._e(),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          }
                                                        ),
                                                        _c(
                                                          "base-text-field",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value:
                                                                  _vm.idade >=
                                                                  18,
                                                                expression:
                                                                  "idade >= 18",
                                                              },
                                                              {
                                                                name: "mask",
                                                                rawName:
                                                                  "v-mask",
                                                                value:
                                                                  "## #####-####",
                                                                expression:
                                                                  "'## #####-####'",
                                                              },
                                                            ],
                                                            staticClass: "mb-3",
                                                            attrs: {
                                                              type: "tel",
                                                              mode: "eager",
                                                              rules:
                                                                _vm.idade >= 18
                                                                  ? "required|cellphone|ddd"
                                                                  : "cellphone|ddd",
                                                              id: "telefoneCelular",
                                                              name: "Telefone celular",
                                                              "data-test-id":
                                                                "informar-telefone",
                                                              label: `Telefone Celular ${
                                                                _vm.idade >= 18
                                                                  ? "*"
                                                                  : ""
                                                              }`,
                                                              disabled:
                                                                _vm.desabilitaInput,
                                                              clearable: "",
                                                              outlined: "",
                                                              hint: "Mesmo número que haverá confirmação via sms para conclusão da proposta",
                                                              "persistent-hint":
                                                                "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.form
                                                                  .telefoneCelular,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.form,
                                                                    "telefoneCelular",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "form.telefoneCelular",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  top: "",
                                                                  right: "",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "activator",
                                                                        fn: function ({
                                                                          on,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "v-icon",
                                                                              _vm._g(
                                                                                {},
                                                                                on
                                                                              ),
                                                                              [
                                                                                _vm._v(
                                                                                  "mdi-information"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "caption",
                                                                    staticStyle:
                                                                      {
                                                                        "line-height":
                                                                          "1",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Mesmo número que haverá confirmação via sms para conclusão da proposta"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._l(
                                                          _vm.telefonesOpcionais,
                                                          function (
                                                            item,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "div",
                                                              { key: index },
                                                              [
                                                                _c(
                                                                  "async-tipos-telefone",
                                                                  {
                                                                    key:
                                                                      "c_tipo-telefone-" +
                                                                      _vm.keyTipoTelefone,
                                                                    scopedSlots:
                                                                      _vm._u(
                                                                        [
                                                                          {
                                                                            key: "default",
                                                                            fn: function ({
                                                                              loading:
                                                                                loadingTipoTelefone,
                                                                              data,
                                                                            }) {
                                                                              return [
                                                                                _c(
                                                                                  "base-wrapper-dialog-options",
                                                                                  {
                                                                                    key:
                                                                                      "dialog_tel_opcional-" +
                                                                                      index,
                                                                                    ref: `dialogTipoTelefoneOpcional_${
                                                                                      index +
                                                                                      1
                                                                                    }`,
                                                                                    refInFor: true,
                                                                                    attrs:
                                                                                      {
                                                                                        headerTitle: `Tipo telefone ${
                                                                                          _vm
                                                                                            .telefonesOpcionais
                                                                                            .length >
                                                                                          1
                                                                                            ? index +
                                                                                              1
                                                                                            : ""
                                                                                        }`,
                                                                                        options:
                                                                                          data,
                                                                                        "value-key":
                                                                                          "id",
                                                                                        "label-key":
                                                                                          "nome",
                                                                                      },
                                                                                    on: {
                                                                                      select:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.$set(
                                                                                            _vm
                                                                                              .telefonesOpcionais[
                                                                                              index
                                                                                            ],
                                                                                            "tipoTelefoneOpcional",
                                                                                            $event.id
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                    scopedSlots:
                                                                                      _vm._u(
                                                                                        [
                                                                                          {
                                                                                            key: "activador",
                                                                                            fn: function ({
                                                                                              on,
                                                                                            }) {
                                                                                              return [
                                                                                                _c(
                                                                                                  "base-text-field",
                                                                                                  _vm._g(
                                                                                                    {
                                                                                                      attrs:
                                                                                                        {
                                                                                                          readonly:
                                                                                                            "",
                                                                                                          rules:
                                                                                                            "required",
                                                                                                          id:
                                                                                                            "text_tipo_opcional-" +
                                                                                                            index,
                                                                                                          "data-test-id": `'informar-telefone-opcional ${
                                                                                                            index +
                                                                                                            1
                                                                                                          }'`,
                                                                                                          name: `Informar telefone adicional (opcional) ${
                                                                                                            index +
                                                                                                            1
                                                                                                          }`,
                                                                                                          value:
                                                                                                            _vm
                                                                                                              .telefonesOpcionais[
                                                                                                              index
                                                                                                            ]
                                                                                                              .tipoTelefoneOpcional ||
                                                                                                            "SEM TELEFONE",
                                                                                                          placeholder: `Clique aqui para selecionar ${
                                                                                                            index +
                                                                                                            1
                                                                                                          } `,
                                                                                                          label: `Informar telefone adicional (opcional) ${
                                                                                                            _vm
                                                                                                              .telefonesOpcionais
                                                                                                              .length >
                                                                                                            1
                                                                                                              ? index +
                                                                                                                1
                                                                                                              : ""
                                                                                                          }`,
                                                                                                          loading:
                                                                                                            loadingTipoTelefone,
                                                                                                          outlined:
                                                                                                            "",
                                                                                                          "append-icon":
                                                                                                            "mdi-chevron-right",
                                                                                                          disabled:
                                                                                                            !_vm
                                                                                                              .telefonesOpcionais[
                                                                                                              index
                                                                                                            ]
                                                                                                              .hasEdit,
                                                                                                        },
                                                                                                    },
                                                                                                    on
                                                                                                  )
                                                                                                ),
                                                                                              ]
                                                                                            },
                                                                                          },
                                                                                        ],
                                                                                        null,
                                                                                        true
                                                                                      ),
                                                                                  }
                                                                                ),
                                                                                _vm
                                                                                  .telefonesOpcionais[
                                                                                  index
                                                                                ]
                                                                                  .tipoTelefoneOpcional
                                                                                  ? _c(
                                                                                      "base-text-field",
                                                                                      {
                                                                                        directives:
                                                                                          [
                                                                                            {
                                                                                              name: "mask",
                                                                                              rawName:
                                                                                                "v-mask",
                                                                                              value:
                                                                                                [
                                                                                                  "## #####-####",
                                                                                                  "## ####-####",
                                                                                                ],
                                                                                              expression:
                                                                                                "['## #####-####', '## ####-####']",
                                                                                            },
                                                                                          ],
                                                                                        attrs:
                                                                                          {
                                                                                            type: "tel",
                                                                                            rules:
                                                                                              _vm
                                                                                                .telefonesOpcionais[
                                                                                                index
                                                                                              ]
                                                                                                .tipoTelefoneOpcional ===
                                                                                              "RESIDENCIAL"
                                                                                                ? "required|ddd|residencePhone"
                                                                                                : "required|ddd|phone",
                                                                                            id:
                                                                                              "text_tel_opcional-" +
                                                                                              index,
                                                                                            name: `Telefone opcional ${
                                                                                              index +
                                                                                              1
                                                                                            }`,
                                                                                            label: `Telefone opcional ${
                                                                                              _vm
                                                                                                .telefonesOpcionais
                                                                                                .length >
                                                                                              1
                                                                                                ? index +
                                                                                                  1
                                                                                                : ""
                                                                                            } *`,
                                                                                            disabled:
                                                                                              !_vm
                                                                                                .telefonesOpcionais[
                                                                                                index
                                                                                              ]
                                                                                                .hasEdit,
                                                                                            clearable:
                                                                                              "",
                                                                                            outlined:
                                                                                              "",
                                                                                          },
                                                                                        model:
                                                                                          {
                                                                                            value:
                                                                                              _vm
                                                                                                .telefonesOpcionais[
                                                                                                index
                                                                                              ]
                                                                                                .telefoneOpcional,
                                                                                            callback:
                                                                                              function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.$set(
                                                                                                  _vm
                                                                                                    .telefonesOpcionais[
                                                                                                    index
                                                                                                  ],
                                                                                                  "telefoneOpcional",
                                                                                                  $$v
                                                                                                )
                                                                                              },
                                                                                            expression:
                                                                                              "telefonesOpcionais[index].telefoneOpcional",
                                                                                          },
                                                                                      }
                                                                                    )
                                                                                  : _vm._e(),
                                                                              ]
                                                                            },
                                                                          },
                                                                        ],
                                                                        null,
                                                                        true
                                                                      ),
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                        _c("base-text-field", {
                                                          attrs: {
                                                            rules:
                                                              _vm.rgObrigatorio
                                                                ? "required"
                                                                : "" ||
                                                                  _vm.idade >=
                                                                    18
                                                                ? "required|identidade"
                                                                : "",
                                                            id: "identidade",
                                                            name: "Identidade",
                                                            "data-test-id":
                                                              "identidade",
                                                            label: `Identidade ${
                                                              _vm.idade >= 18
                                                                ? "*"
                                                                : ""
                                                            }`,
                                                            maxlength: "20",
                                                            clearable: "",
                                                            outlined: "",
                                                          },
                                                          model: {
                                                            value: _vm.form.rg,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.form,
                                                                "rg",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "form.rg",
                                                          },
                                                        }),
                                                        _vm.emissorObrigatorio &&
                                                        _vm.form
                                                          .administradora !==
                                                          "plural"
                                                          ? _c(
                                                              "div",
                                                              [
                                                                _c(
                                                                  "base-text-field",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "mask",
                                                                          rawName:
                                                                            "v-mask",
                                                                          value:
                                                                            "AAAAAAAAA",
                                                                          expression:
                                                                            "'AAAAAAAAA'",
                                                                        },
                                                                      ],
                                                                    attrs: {
                                                                      rules:
                                                                        _vm.emissorObrigatorio
                                                                          ? "required"
                                                                          : "",
                                                                      id: "rgOrgaoEmissor",
                                                                      name: "Orgão Emissor",
                                                                      "data-test-id":
                                                                        "orgao-emissor",
                                                                      label: `Orgão Emissor ${
                                                                        _vm.emissorObrigatorio
                                                                          ? "*"
                                                                          : ""
                                                                      }`,
                                                                      clearable:
                                                                        "",
                                                                      outlined:
                                                                        "",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.form
                                                                          .rgOrgaoEmissor,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.form,
                                                                            "rgOrgaoEmissor",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "form.rgOrgaoEmissor",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm.emissorObrigatorio &&
                                                        _vm.form
                                                          .administradora ===
                                                          "plural"
                                                          ? _c(
                                                              "div",
                                                              [
                                                                _c(
                                                                  "base-wrapper-dialog-options",
                                                                  {
                                                                    attrs: {
                                                                      headerTitle:
                                                                        "Orgão Emissor",
                                                                      options:
                                                                        _vm.listOrgaoEmissor,
                                                                      "value-key":
                                                                        "id",
                                                                      "label-key":
                                                                        "nome",
                                                                    },
                                                                    on: {
                                                                      select:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.$set(
                                                                            _vm.form,
                                                                            "rgOrgaoEmissor",
                                                                            $event.id
                                                                          )
                                                                        },
                                                                    },
                                                                    scopedSlots:
                                                                      _vm._u(
                                                                        [
                                                                          {
                                                                            key: "activador",
                                                                            fn: function ({
                                                                              on,
                                                                            }) {
                                                                              return [
                                                                                _c(
                                                                                  "base-text-field",
                                                                                  _vm._g(
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          rules:
                                                                                            _vm.emissorObrigatorio
                                                                                              ? "required"
                                                                                              : "",
                                                                                          placeholder:
                                                                                            "Clique aqui para selecionar",
                                                                                          id: "rgOrgaoEmissor",
                                                                                          name: "Orgão Emissor",
                                                                                          value:
                                                                                            _vm
                                                                                              .form
                                                                                              .rgOrgaoEmissor,
                                                                                          label: `Orgão Emissor ${
                                                                                            _vm.emissorObrigatorio
                                                                                              ? "*"
                                                                                              : ""
                                                                                          }`,
                                                                                          outlined:
                                                                                            "",
                                                                                          "append-icon":
                                                                                            "mdi-chevron-right",
                                                                                        },
                                                                                    },
                                                                                    on
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            },
                                                                          },
                                                                        ],
                                                                        null,
                                                                        true
                                                                      ),
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm.ufObrigatorio
                                                          ? _c(
                                                              "div",
                                                              [
                                                                _c(
                                                                  "base-wrapper-dialog-options",
                                                                  {
                                                                    attrs: {
                                                                      headerTitle:
                                                                        "UF Orgão Emissor",
                                                                      options:
                                                                        _vm.listUf,
                                                                      "value-key":
                                                                        "id",
                                                                      "label-key":
                                                                        "nome",
                                                                    },
                                                                    on: {
                                                                      select:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.$set(
                                                                            _vm.form,
                                                                            "rgOrgaoEmissorUf",
                                                                            $event.id
                                                                          )
                                                                        },
                                                                    },
                                                                    scopedSlots:
                                                                      _vm._u(
                                                                        [
                                                                          {
                                                                            key: "activador",
                                                                            fn: function ({
                                                                              on,
                                                                            }) {
                                                                              return [
                                                                                _c(
                                                                                  "base-text-field",
                                                                                  _vm._g(
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          rules:
                                                                                            _vm.ufObrigatorio
                                                                                              ? "required"
                                                                                              : "",
                                                                                          placeholder:
                                                                                            "Clique aqui para selecionar",
                                                                                          id: "rgOrgaoEmissorUf",
                                                                                          name: "UF Orgão Emissor",
                                                                                          value:
                                                                                            _vm
                                                                                              .form
                                                                                              .rgOrgaoEmissorUf,
                                                                                          label: `UF Orgão Emissor ${
                                                                                            _vm.ufObrigatorio
                                                                                              ? "*"
                                                                                              : ""
                                                                                          }`,
                                                                                          outlined:
                                                                                            "",
                                                                                          "append-icon":
                                                                                            "mdi-chevron-right",
                                                                                        },
                                                                                    },
                                                                                    on
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            },
                                                                          },
                                                                        ],
                                                                        null,
                                                                        true
                                                                      ),
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm.dataExpedicaoObrigatorio
                                                          ? _c(
                                                              "div",
                                                              [
                                                                _c(
                                                                  "base-text-field",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "mask",
                                                                          rawName:
                                                                            "v-mask",
                                                                          value:
                                                                            "##/##/####",
                                                                          expression:
                                                                            "'##/##/####'",
                                                                        },
                                                                      ],
                                                                    attrs: {
                                                                      rules:
                                                                        _vm.dataExpedicaoObrigatorio
                                                                          ? "required"
                                                                          : "",
                                                                      id: "rgDataExpedicao",
                                                                      name: "Data de Expedição",
                                                                      label: `Data de Expedição ${
                                                                        _vm.dataExpedicaoObrigatorio
                                                                          ? "*"
                                                                          : ""
                                                                      }`,
                                                                      clearable:
                                                                        "",
                                                                      outlined:
                                                                        "",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.form
                                                                          .rgDataExpedicao,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.form,
                                                                            "rgDataExpedicao",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "form.rgDataExpedicao",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm.cnsObrigatorio
                                                          ? _c(
                                                              "div",
                                                              [
                                                                _c(
                                                                  "base-text-field",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "mask",
                                                                          rawName:
                                                                            "v-mask",
                                                                          value:
                                                                            "###.####.####.####",
                                                                          expression:
                                                                            "'###.####.####.####'",
                                                                        },
                                                                      ],
                                                                    attrs: {
                                                                      rules:
                                                                        _vm.cnsObrigatorio
                                                                          ? "required|cns"
                                                                          : "",
                                                                      id: "numeroCns",
                                                                      name: "Cartão Nacional Saúde",
                                                                      "data-test-id":
                                                                        "numero-cns",
                                                                      label: `Cartão Nacional Saúde ${
                                                                        _vm.cnsObrigatorio
                                                                          ? "*"
                                                                          : ""
                                                                      }`,
                                                                      inputmode:
                                                                        "numeric",
                                                                      clearable:
                                                                        "",
                                                                      outlined:
                                                                        "",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.form
                                                                          .cns,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.form,
                                                                            "cns",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "form.cns",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm.dnvObrigatorio
                                                          ? _c(
                                                              "div",
                                                              [
                                                                _c(
                                                                  "base-text-field",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "mask",
                                                                          rawName:
                                                                            "v-mask",
                                                                          value:
                                                                            "##-########-#",
                                                                          expression:
                                                                            "'##-########-#'",
                                                                        },
                                                                      ],
                                                                    attrs: {
                                                                      rules:
                                                                        _vm.dnvObrigatorio &&
                                                                        _vm
                                                                          .propostaState
                                                                          .plano
                                                                          .operadora !==
                                                                          "AMPLA"
                                                                          ? "required"
                                                                          : "",
                                                                      id: "numeroDnv",
                                                                      name: "Declaração Nascido Vivo",
                                                                      "data-test-id":
                                                                        "numero-dnv",
                                                                      label: `Declaração Nascido Vivo ${
                                                                        _vm.dnvObrigatorio &&
                                                                        _vm
                                                                          .propostaState
                                                                          .plano
                                                                          .operadora !==
                                                                          "AMPLA"
                                                                          ? "*"
                                                                          : ""
                                                                      }`,
                                                                      maxlength:
                                                                        "12",
                                                                      inputmode:
                                                                        "numeric",
                                                                      clearable:
                                                                        "",
                                                                      outlined:
                                                                        "",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.form
                                                                          .declaracaoNascidoVivo,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.form,
                                                                            "declaracaoNascidoVivo",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "form.declaracaoNascidoVivo",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _c("base-text-field", {
                                                          directives: [
                                                            {
                                                              name: "mask",
                                                              rawName: "v-mask",
                                                              value:
                                                                "###.#####.##-#",
                                                              expression:
                                                                "'###.#####.##-#'",
                                                            },
                                                          ],
                                                          attrs: {
                                                            rules:
                                                              _vm.pisObrigatorio
                                                                ? "required"
                                                                : "",
                                                            id: "pisPasep",
                                                            name: "PIS/PASEP",
                                                            "data-test-id":
                                                              "pis-pasep",
                                                            label:
                                                              _vm.pisObrigatorio
                                                                ? "PIS/PASEP*"
                                                                : "PIS/PASEP",
                                                            inputmode:
                                                              "numeric",
                                                            clearable: "",
                                                            outlined: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.form.pisPasep,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.form,
                                                                "pisPasep",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "form.pisPasep",
                                                          },
                                                        }),
                                                        _vm.dadosTitularState
                                                          .entidade ===
                                                        "ANAJUSTRA"
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "input-dt-nascimento",
                                                              },
                                                              [
                                                                _c(
                                                                  "base-text-field",
                                                                  {
                                                                    attrs: {
                                                                      autoBlur: 10,
                                                                      rules:
                                                                        _vm
                                                                          .dadosTitularState
                                                                          .entidade ===
                                                                        "ANAJUSTRA"
                                                                          ? "required"
                                                                          : "",
                                                                      inputmode:
                                                                        "numeric",
                                                                      type: "text",
                                                                      "data-test-id":
                                                                        "preencher-matriculaFuncional",
                                                                      id: "matriculaFuncional",
                                                                      name: "matriculaFuncional",
                                                                      label:
                                                                        "Matrícula Funcional",
                                                                      clearable:
                                                                        "",
                                                                      outlined:
                                                                        "",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.form
                                                                          .matriculaFuncional,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.form,
                                                                            "matriculaFuncional",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "form.matriculaFuncional",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm.form
                                                          .administradora ===
                                                        "alianca"
                                                          ? _c(
                                                              "base-wrapper-dialog-options",
                                                              {
                                                                ref: "dialogEmpossadoPensionista",
                                                                attrs: {
                                                                  headerTitle:
                                                                    "Empossado/Pensionista",
                                                                  options: [
                                                                    {
                                                                      id: "Sim",
                                                                      nome: "Sim",
                                                                      value: true,
                                                                    },
                                                                    {
                                                                      id: "Não",
                                                                      nome: "Não",
                                                                      value: false,
                                                                    },
                                                                  ],
                                                                  "value-key":
                                                                    "id",
                                                                  "label-key":
                                                                    "nome",
                                                                },
                                                                on: {
                                                                  select:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.$set(
                                                                        _vm.form,
                                                                        "empossadoPensionista",
                                                                        $event.id
                                                                      )
                                                                    },
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "activador",
                                                                        fn: function ({
                                                                          on,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "base-text-field",
                                                                              _vm._g(
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      readonly:
                                                                                        "",
                                                                                      rules:
                                                                                        "required",
                                                                                      "data-test-id":
                                                                                        "reducao-carencia",
                                                                                      id: "empossadoPensionista",
                                                                                      name: "EmpossadoPensionista",
                                                                                      value:
                                                                                        _vm
                                                                                          .form
                                                                                          .empossadoPensionista,
                                                                                      placeholder:
                                                                                        "Clique aqui para selecionar",
                                                                                      label:
                                                                                        "Recém Empossado/Pensionista",
                                                                                      outlined:
                                                                                        "",
                                                                                      "append-icon":
                                                                                        "mdi-chevron-right",
                                                                                    },
                                                                                },
                                                                                on
                                                                              )
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              }
                                                            )
                                                          : _vm._e(),
                                                        _vm.propostaState
                                                          .corretor
                                                          .tipoCorretor ==
                                                          "Externo" &&
                                                        _vm.propostaState.plano
                                                          .operadora ==
                                                          "Unimed BH"
                                                          ? _c(
                                                              "v-row",
                                                              [
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    staticClass:
                                                                      "pb-0",
                                                                    attrs: {
                                                                      cols: "12",
                                                                      md:
                                                                        _vm.form
                                                                          .sumula &&
                                                                        _vm.form
                                                                          .sumula ==
                                                                          "Sim"
                                                                          ? "6"
                                                                          : "12",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "base-wrapper-dialog-options",
                                                                      {
                                                                        ref: "dialogEmpossadoPensionista",
                                                                        attrs: {
                                                                          headerTitle:
                                                                            "Súmula 21",
                                                                          options:
                                                                            [
                                                                              {
                                                                                id: "Sim",
                                                                                nome: "Sim",
                                                                                value: true,
                                                                              },
                                                                              {
                                                                                id: "Não",
                                                                                nome: "Não",
                                                                                value: false,
                                                                              },
                                                                            ],
                                                                          "value-key":
                                                                            "id",
                                                                          "label-key":
                                                                            "nome",
                                                                        },
                                                                        on: {
                                                                          select:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                _vm.form,
                                                                                "sumula",
                                                                                $event.id
                                                                              )
                                                                            },
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              {
                                                                                key: "activador",
                                                                                fn: function ({
                                                                                  on,
                                                                                }) {
                                                                                  return [
                                                                                    _c(
                                                                                      "base-text-field",
                                                                                      _vm._g(
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              readonly:
                                                                                                "",
                                                                                              rules:
                                                                                                "required",
                                                                                              "data-test-id":
                                                                                                "reducao-carencia",
                                                                                              id: "sumula",
                                                                                              name: "Sumula",
                                                                                              value:
                                                                                                _vm
                                                                                                  .form
                                                                                                  .sumula,
                                                                                              placeholder:
                                                                                                "Clique aqui para selecionar",
                                                                                              label:
                                                                                                "Súmula 21",
                                                                                              outlined:
                                                                                                "",
                                                                                              "append-icon":
                                                                                                "mdi-chevron-right",
                                                                                            },
                                                                                        },
                                                                                        on
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                },
                                                                              },
                                                                            ],
                                                                            null,
                                                                            true
                                                                          ),
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm.form
                                                                  .sumula &&
                                                                _vm.form
                                                                  .sumula ==
                                                                  "Sim"
                                                                  ? _c(
                                                                      "v-col",
                                                                      {
                                                                        staticClass:
                                                                          "pb-0",
                                                                        attrs: {
                                                                          cols: "12",
                                                                          md: "6",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "h2",
                                                                          {
                                                                            staticClass:
                                                                              "primary--text mb-2 subtitle-1 font-weight-bold",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "Tempo plano anterior"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-divider"
                                                                        ),
                                                                        _c(
                                                                          "validation-provider",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                name: "Tempo do Plano Anterior",
                                                                                vid: "sumulaPlanoAnterior",
                                                                                rules:
                                                                                  _vm
                                                                                    .form
                                                                                    .sumula
                                                                                    ? "required"
                                                                                    : "",
                                                                              },
                                                                            scopedSlots:
                                                                              _vm._u(
                                                                                [
                                                                                  {
                                                                                    key: "default",
                                                                                    fn: function ({
                                                                                      errors,
                                                                                    }) {
                                                                                      return [
                                                                                        _c(
                                                                                          "v-radio-group",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                mandatory: false,
                                                                                                "error-messages":
                                                                                                  errors,
                                                                                              },
                                                                                            model:
                                                                                              {
                                                                                                value:
                                                                                                  _vm
                                                                                                    .form
                                                                                                    .sumulaPlanoAnterior,
                                                                                                callback:
                                                                                                  function (
                                                                                                    $$v
                                                                                                  ) {
                                                                                                    _vm.$set(
                                                                                                      _vm.form,
                                                                                                      "sumulaPlanoAnterior",
                                                                                                      $$v
                                                                                                    )
                                                                                                  },
                                                                                                expression:
                                                                                                  "form.sumulaPlanoAnterior",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-radio",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "mb-4",
                                                                                                attrs:
                                                                                                  {
                                                                                                    "data-test-id":
                                                                                                      "maior-24",
                                                                                                    label:
                                                                                                      "MAIOR QUE 24 MESES",
                                                                                                    value: 1,
                                                                                                  },
                                                                                              }
                                                                                            ),
                                                                                            _c(
                                                                                              "v-divider"
                                                                                            ),
                                                                                            _c(
                                                                                              "v-radio",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "mb-4 mt-4",
                                                                                                attrs:
                                                                                                  {
                                                                                                    "data-test-id":
                                                                                                      "menor-24",
                                                                                                    label:
                                                                                                      "MENOR QUE 24 MESES",
                                                                                                    value: 0,
                                                                                                  },
                                                                                              }
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                      ]
                                                                                    },
                                                                                  },
                                                                                ],
                                                                                null,
                                                                                true
                                                                              ),
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      2
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                      _c("validation-observer", {
                                        ref: "enderecoResidencial",
                                        attrs: { slim: "" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ valid, failed }) {
                                              return [
                                                _c(
                                                  "v-expansion-panel",
                                                  [
                                                    _c(
                                                      "v-expansion-panel-header",
                                                      {
                                                        staticClass:
                                                          "primary--text subtitle-1 font-weight-bold pa-0 pt-4 pb-3",
                                                        staticStyle: {
                                                          "min-height": "auto",
                                                          "justify-content":
                                                            "space-between",
                                                        },
                                                        attrs: {
                                                          "data-test-id":
                                                            "expandir-endereco-residencial",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "actions",
                                                              fn: function () {
                                                                return [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        color:
                                                                          "primary",
                                                                        size: "30",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "$expand"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                              proxy: true,
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex align-center",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Endereço residencial "
                                                            ),
                                                            _c("v-spacer"),
                                                            valid
                                                              ? _c(
                                                                  "v-icon",
                                                                  {
                                                                    staticClass:
                                                                      "mr-2",
                                                                    attrs: {
                                                                      color:
                                                                        "green",
                                                                      size: "25",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-check-circle"
                                                                    ),
                                                                  ]
                                                                )
                                                              : failed
                                                              ? _c(
                                                                  "v-icon",
                                                                  {
                                                                    staticClass:
                                                                      "mr-2",
                                                                    attrs: {
                                                                      color:
                                                                        "red",
                                                                      size: "25",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-alert-circle"
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                    _c("v-divider"),
                                                    _c(
                                                      "v-expansion-panel-content",
                                                      {
                                                        staticClass: "pt-4",
                                                        attrs: { eager: "" },
                                                      },
                                                      [
                                                        _c(
                                                          "async-cep-residencial",
                                                          {
                                                            attrs: {
                                                              cep: _vm
                                                                .enderecoResidencial
                                                                .cep,
                                                              loadingMounted:
                                                                _vm.loadingMountedCepResidencial,
                                                            },
                                                            on: {
                                                              success:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.successCep(
                                                                    "residencial",
                                                                    $event
                                                                  )
                                                                },
                                                              error: function (
                                                                $event
                                                              ) {
                                                                _vm.loadingMountedCepResidencial = false
                                                              },
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "default",
                                                                  fn: function ({
                                                                    loading:
                                                                      loadingRequestCep,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "base-text-field",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "mask",
                                                                                rawName:
                                                                                  "v-mask",
                                                                                value:
                                                                                  "#####-###",
                                                                                expression:
                                                                                  "'#####-###'",
                                                                              },
                                                                            ],
                                                                          attrs:
                                                                            {
                                                                              rules:
                                                                                "required|min:9",
                                                                              inputmode:
                                                                                "numeric",
                                                                              id: "cep",
                                                                              name: "CEP",
                                                                              "data-test-id":
                                                                                "cep-residencial",
                                                                              disabled:
                                                                                "",
                                                                              label:
                                                                                "CEP *",
                                                                              loading:
                                                                                loadingRequestCep,
                                                                              clearable:
                                                                                "",
                                                                              outlined:
                                                                                "",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .enderecoResidencial
                                                                                  .cep,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.enderecoResidencial,
                                                                                    "cep",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "enderecoResidencial.cep",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          }
                                                        ),
                                                        _c("base-text-field", {
                                                          directives: [
                                                            {
                                                              name: "mask",
                                                              rawName: "v-mask",
                                                              value: "AA",
                                                              expression:
                                                                "'AA'",
                                                            },
                                                          ],
                                                          attrs: {
                                                            rules: "required",
                                                            "data-test-id":
                                                              "uf",
                                                            id: "uf",
                                                            name: "UF",
                                                            disabled:
                                                              _vm.habilitaUf,
                                                            value:
                                                              _vm
                                                                .enderecoResidencial
                                                                .estado,
                                                            label: "UF *",
                                                            outlined: "",
                                                          },
                                                        }),
                                                        _c("base-text-field", {
                                                          attrs: {
                                                            disabled:
                                                              _vm.habilitaCidade,
                                                            rules: "required",
                                                            "data-test-id":
                                                              "municipio",
                                                            id: "municipio",
                                                            name: "Município",
                                                            value:
                                                              _vm
                                                                .enderecoResidencial
                                                                .cidade,
                                                            label:
                                                              "Município *",
                                                            outlined: "",
                                                          },
                                                        }),
                                                        _c("base-text-field", {
                                                          attrs: {
                                                            rules: "required",
                                                            "data-test-id":
                                                              "bairro",
                                                            id: "bairro",
                                                            name: "Bairro",
                                                            disabled:
                                                              _vm.habilitaBairro,
                                                            label: "Bairro *",
                                                            clearable: "",
                                                            outlined: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .enderecoResidencial
                                                                .bairro,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.enderecoResidencial,
                                                                "bairro",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "enderecoResidencial.bairro",
                                                          },
                                                        }),
                                                        _c("base-text-field", {
                                                          attrs: {
                                                            rules: "required",
                                                            id: "logradouro",
                                                            "data-test-id":
                                                              "logradouro",
                                                            name: "Logradouro",
                                                            disabled:
                                                              _vm.habilitaLogradouro,
                                                            label:
                                                              "Logradouro *",
                                                            clearable: "",
                                                            outlined: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .enderecoResidencial
                                                                .logradouro,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.enderecoResidencial,
                                                                "logradouro",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "enderecoResidencial.logradouro",
                                                          },
                                                        }),
                                                        _c("base-text-field", {
                                                          directives: [
                                                            {
                                                              name: "mask",
                                                              rawName: "v-mask",
                                                              value: [
                                                                "############",
                                                              ],
                                                              expression:
                                                                "['############']",
                                                            },
                                                          ],
                                                          attrs: {
                                                            rules: "required",
                                                            "data-test-id":
                                                              "numero-casa",
                                                            id: "numero",
                                                            name: "Número",
                                                            label: "Número *",
                                                            clearable: "",
                                                            outlined: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .enderecoResidencial
                                                                .numero,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.enderecoResidencial,
                                                                "numero",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "enderecoResidencial.numero",
                                                          },
                                                        }),
                                                        _c("base-text-field", {
                                                          attrs: {
                                                            rules: "",
                                                            id: "complemento",
                                                            "data-test-id":
                                                              "complemento",
                                                            name: "Complemento",
                                                            label:
                                                              "Complemento",
                                                            clearable: "",
                                                            outlined: "",
                                                            maxlength: "30",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .enderecoResidencial
                                                                .complemento,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.enderecoResidencial,
                                                                "complemento",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "enderecoResidencial.complemento",
                                                          },
                                                        }),
                                                        _c(
                                                          "validation-provider",
                                                          {
                                                            staticClass:
                                                              "d-flex align-center mb-5",
                                                            attrs: {
                                                              tag: "div",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "primary--text subtitle-2 font-weight-regular pr-4 mt-1",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Possui endereço comercial?"
                                                                ),
                                                              ]
                                                            ),
                                                            _c("v-spacer"),
                                                            _c("v-switch", {
                                                              staticClass:
                                                                "ma-0",
                                                              attrs: {
                                                                "hide-details":
                                                                  "",
                                                                disabled:
                                                                  !_vm.possuiEnderecoComercialState &&
                                                                  _vm.desabilitaInput,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.possuiEnderecoComercial,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.possuiEnderecoComercial =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "possuiEnderecoComercial",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                      _vm.possuiEnderecoComercial
                                        ? _c("validation-observer", {
                                            ref: "enderecoComercial",
                                            attrs: { slim: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({
                                                    valid,
                                                    failed,
                                                  }) {
                                                    return [
                                                      _c(
                                                        "v-expansion-panel",
                                                        [
                                                          _c(
                                                            "v-expansion-panel-header",
                                                            {
                                                              staticClass:
                                                                "primary--text subtitle-1 font-weight-bold pa-0 pt-4 pb-3",
                                                              staticStyle: {
                                                                "min-height":
                                                                  "auto",
                                                                "justify-content":
                                                                  "space-between",
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "actions",
                                                                      fn: function () {
                                                                        return [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    "primary",
                                                                                  size: "30",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "$expand"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      },
                                                                      proxy: true,
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "d-flex align-center",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Endereço comercial "
                                                                  ),
                                                                  _c(
                                                                    "v-spacer"
                                                                  ),
                                                                  valid
                                                                    ? _c(
                                                                        "v-icon",
                                                                        {
                                                                          staticClass:
                                                                            "mr-2",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                "green",
                                                                              size: "25",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-check-circle"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : failed
                                                                    ? _c(
                                                                        "v-icon",
                                                                        {
                                                                          staticClass:
                                                                            "mr-2",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                "red",
                                                                              size: "25",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-alert-circle"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          ),
                                                          _c("v-divider"),
                                                          _c(
                                                            "v-expansion-panel-content",
                                                            {
                                                              staticClass:
                                                                "pt-4",
                                                              attrs: {
                                                                eager: "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "async-cep-comercial",
                                                                {
                                                                  attrs: {
                                                                    cep: _vm
                                                                      .enderecoComercial
                                                                      .cep,
                                                                    loadingMounted:
                                                                      _vm.loadingMountedCepComercial,
                                                                  },
                                                                  on: {
                                                                    success:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.successCep(
                                                                          "comercial",
                                                                          $event
                                                                        )
                                                                      },
                                                                    error:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        _vm.loadingMountedCepComercial = false
                                                                      },
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "default",
                                                                          fn: function ({
                                                                            loading:
                                                                              loadingRequestCep,
                                                                          }) {
                                                                            return [
                                                                              _c(
                                                                                "base-text-field",
                                                                                {
                                                                                  directives:
                                                                                    [
                                                                                      {
                                                                                        name: "mask",
                                                                                        rawName:
                                                                                          "v-mask",
                                                                                        value:
                                                                                          "#####-###",
                                                                                        expression:
                                                                                          "'#####-###'",
                                                                                      },
                                                                                    ],
                                                                                  attrs:
                                                                                    {
                                                                                      rules:
                                                                                        "required|min:9",
                                                                                      inputmode:
                                                                                        "numeric",
                                                                                      id: "cep",
                                                                                      name: "CEP",
                                                                                      "data-test-id":
                                                                                        "cep-comercial",
                                                                                      label:
                                                                                        "CEP *",
                                                                                      clearable:
                                                                                        "",
                                                                                      loading:
                                                                                        loadingRequestCep,
                                                                                      outlined:
                                                                                        "",
                                                                                    },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        _vm
                                                                                          .enderecoComercial
                                                                                          .cep,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            _vm.enderecoComercial,
                                                                                            "cep",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "enderecoComercial.cep",
                                                                                    },
                                                                                }
                                                                              ),
                                                                              _c(
                                                                                "base-text-field",
                                                                                {
                                                                                  directives:
                                                                                    [
                                                                                      {
                                                                                        name: "mask",
                                                                                        rawName:
                                                                                          "v-mask",
                                                                                        value:
                                                                                          "AA",
                                                                                        expression:
                                                                                          "'AA'",
                                                                                      },
                                                                                    ],
                                                                                  attrs:
                                                                                    {
                                                                                      rules:
                                                                                        "required",
                                                                                      id: "uf",
                                                                                      name: "UF",
                                                                                      "data-test-id":
                                                                                        "uf-comercial",
                                                                                      value:
                                                                                        _vm
                                                                                          .enderecoComercial
                                                                                          .estado,
                                                                                      disabled:
                                                                                        _vm
                                                                                          .enderecoComercial
                                                                                          .estado
                                                                                          ? true
                                                                                          : false,
                                                                                      label:
                                                                                        "UF *",
                                                                                      outlined:
                                                                                        "",
                                                                                    },
                                                                                }
                                                                              ),
                                                                              _c(
                                                                                "base-text-field",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      disabled:
                                                                                        _vm
                                                                                          .enderecoComercial
                                                                                          .cidade
                                                                                          ? true
                                                                                          : false,
                                                                                      rules:
                                                                                        "required",
                                                                                      id: "municipio",
                                                                                      "data-test-id":
                                                                                        "municipio-comercial",
                                                                                      name: "Município",
                                                                                      value:
                                                                                        _vm
                                                                                          .enderecoComercial
                                                                                          .cidade,
                                                                                      label:
                                                                                        "Município *",
                                                                                      outlined:
                                                                                        "",
                                                                                    },
                                                                                }
                                                                              ),
                                                                              _c(
                                                                                "base-text-field",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      rules:
                                                                                        "required",
                                                                                      "data-test-id":
                                                                                        "bairro-comercial",
                                                                                      id: "bairro",
                                                                                      name: "Bairro",
                                                                                      label:
                                                                                        "Bairro *",
                                                                                      clearable:
                                                                                        "",
                                                                                      outlined:
                                                                                        "",
                                                                                    },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        _vm
                                                                                          .enderecoComercial
                                                                                          .bairro,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            _vm.enderecoComercial,
                                                                                            "bairro",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "enderecoComercial.bairro",
                                                                                    },
                                                                                }
                                                                              ),
                                                                              _c(
                                                                                "base-text-field",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      rules:
                                                                                        "required",
                                                                                      id: "logradouro",
                                                                                      "data-test-id":
                                                                                        "logradouro-comercial",
                                                                                      name: "Logradouro",
                                                                                      label:
                                                                                        "Logradouro *",
                                                                                      clearable:
                                                                                        "",
                                                                                      outlined:
                                                                                        "",
                                                                                    },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        _vm
                                                                                          .enderecoComercial
                                                                                          .logradouro,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            _vm.enderecoComercial,
                                                                                            "logradouro",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "enderecoComercial.logradouro",
                                                                                    },
                                                                                }
                                                                              ),
                                                                              _c(
                                                                                "base-text-field",
                                                                                {
                                                                                  directives:
                                                                                    [
                                                                                      {
                                                                                        name: "mask",
                                                                                        rawName:
                                                                                          "v-mask",
                                                                                        value:
                                                                                          [
                                                                                            "############",
                                                                                          ],
                                                                                        expression:
                                                                                          "['############']",
                                                                                      },
                                                                                    ],
                                                                                  attrs:
                                                                                    {
                                                                                      rules:
                                                                                        "required",
                                                                                      id: "numero",
                                                                                      "data-test-id":
                                                                                        "numero-comercial",
                                                                                      name: "Número",
                                                                                      label:
                                                                                        "Número *",
                                                                                      clearable:
                                                                                        "",
                                                                                      outlined:
                                                                                        "",
                                                                                    },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        _vm
                                                                                          .enderecoComercial
                                                                                          .numero,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            _vm.enderecoComercial,
                                                                                            "numero",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "enderecoComercial.numero",
                                                                                    },
                                                                                }
                                                                              ),
                                                                              _c(
                                                                                "base-text-field",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      rules:
                                                                                        "",
                                                                                      id: "complemento",
                                                                                      "data-test-id":
                                                                                        "complemento-comercial",
                                                                                      name: "Complemento",
                                                                                      label:
                                                                                        "Complemento",
                                                                                      clearable:
                                                                                        "",
                                                                                      outlined:
                                                                                        "",
                                                                                      maxlength:
                                                                                        "30",
                                                                                    },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        _vm
                                                                                          .enderecoComercial
                                                                                          .complemento,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            _vm.enderecoComercial,
                                                                                            "complemento",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "enderecoComercial.complemento",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      true
                                                                    ),
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              458733576
                                            ),
                                          })
                                        : _vm._e(),
                                      _vm.enderecoComercial &&
                                      _vm.enderecoComercial.cep &&
                                      _vm.possuiEnderecoComercial
                                        ? _c("validation-observer", {
                                            ref: "enderecoCorrespondencia",
                                            attrs: { slim: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({
                                                    valid,
                                                    failed,
                                                  }) {
                                                    return [
                                                      _c(
                                                        "v-expansion-panel",
                                                        [
                                                          _c(
                                                            "v-expansion-panel-header",
                                                            {
                                                              staticClass:
                                                                "primary--text subtitle-1 font-weight-bold pa-0 pt-4 pb-3",
                                                              staticStyle: {
                                                                "min-height":
                                                                  "auto",
                                                                "justify-content":
                                                                  "space-between",
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "actions",
                                                                      fn: function () {
                                                                        return [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    "primary",
                                                                                  size: "30",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "$expand"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      },
                                                                      proxy: true,
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "d-flex align-center",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Endereço correspondência "
                                                                  ),
                                                                  _c(
                                                                    "v-spacer"
                                                                  ),
                                                                  valid
                                                                    ? _c(
                                                                        "v-icon",
                                                                        {
                                                                          staticClass:
                                                                            "mr-2",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                "green",
                                                                              size: "25",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-check-circle"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : failed
                                                                    ? _c(
                                                                        "v-icon",
                                                                        {
                                                                          staticClass:
                                                                            "mr-2",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                "red",
                                                                              size: "25",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-alert-circle"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          ),
                                                          _c("v-divider"),
                                                          _c(
                                                            "v-expansion-panel-content",
                                                            {
                                                              staticClass:
                                                                "pt-4",
                                                              attrs: {
                                                                eager: "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "validation-provider",
                                                                {
                                                                  attrs: {
                                                                    slim: "",
                                                                    name: "Endereço correspondência",
                                                                    vid: "enderecoCorrespondencia",
                                                                    rules:
                                                                      "required",
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "default",
                                                                          fn: function ({
                                                                            errors,
                                                                          }) {
                                                                            return [
                                                                              _c(
                                                                                "v-radio-group",
                                                                                {
                                                                                  staticClass:
                                                                                    "ma-0 pa-0",
                                                                                  attrs:
                                                                                    {
                                                                                      value:
                                                                                        _vm.enderecoCobranca,
                                                                                      mandatory: false,
                                                                                      "error-messages":
                                                                                        errors,
                                                                                    },
                                                                                  on: {
                                                                                    change:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.setEnderecoCobranca(
                                                                                          $event
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-radio",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mb-4",
                                                                                      attrs:
                                                                                        {
                                                                                          label:
                                                                                            "Residencial",
                                                                                          value:
                                                                                            "residencial",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                  _c(
                                                                                    "v-radio",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          label:
                                                                                            "Comercial",
                                                                                          value:
                                                                                            "comercial",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      true
                                                                    ),
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              2142525023
                                            ),
                                          })
                                        : _vm._e(),
                                      _vm.exibirReducaoCarencia
                                        ? _c("validation-observer", {
                                            ref: "reducaoCarencia",
                                            attrs: { slim: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({
                                                    valid,
                                                    failed,
                                                  }) {
                                                    return [
                                                      _c(
                                                        "v-expansion-panel",
                                                        {
                                                          on: {
                                                            click:
                                                              _vm.setCarencia,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-expansion-panel-header",
                                                            {
                                                              staticClass:
                                                                "primary--text subtitle-1 font-weight-bold pa-0 pt-4 pb-3",
                                                              staticStyle: {
                                                                "min-height":
                                                                  "auto",
                                                                "justify-content":
                                                                  "space-between",
                                                              },
                                                              attrs: {
                                                                "data-test-id":
                                                                  "expandir-reducao-carencia",
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "actions",
                                                                      fn: function () {
                                                                        return [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    "primary",
                                                                                  size: "30",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "$expand"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      },
                                                                      proxy: true,
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "d-flex align-center",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Redução de carência "
                                                                  ),
                                                                  _c(
                                                                    "v-spacer"
                                                                  ),
                                                                  valid
                                                                    ? _c(
                                                                        "v-icon",
                                                                        {
                                                                          staticClass:
                                                                            "mr-2",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                "green",
                                                                              size: "25",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-check-circle"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : failed
                                                                    ? _c(
                                                                        "v-icon",
                                                                        {
                                                                          staticClass:
                                                                            "mr-2",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                "red",
                                                                              size: "25",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-alert-circle"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          ),
                                                          _c("v-divider"),
                                                          _c(
                                                            "v-expansion-panel-content",
                                                            {
                                                              staticClass:
                                                                "pt-4",
                                                              attrs: {
                                                                eager: "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "base-wrapper-dialog-options",
                                                                {
                                                                  ref: "dialogReducaoCarencia",
                                                                  attrs: {
                                                                    headerTitle:
                                                                      "Redução de carência",
                                                                    options: [
                                                                      {
                                                                        id: "Sim",
                                                                        nome: "Sim",
                                                                        value: true,
                                                                      },
                                                                      {
                                                                        id: "Não",
                                                                        nome: "Não",
                                                                        value: false,
                                                                      },
                                                                    ],
                                                                    "value-key":
                                                                      "value",
                                                                    "label-key":
                                                                      "nome",
                                                                    optionSelected:
                                                                      _vm.form
                                                                        .reducaoCarencia,
                                                                  },
                                                                  on: {
                                                                    select:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          _vm.form,
                                                                          "reducaoCarencia",
                                                                          $event.id
                                                                        )
                                                                      },
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "activador",
                                                                          fn: function ({
                                                                            on,
                                                                          }) {
                                                                            return [
                                                                              _c(
                                                                                "base-text-field",
                                                                                _vm._g(
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        readonly:
                                                                                          "",
                                                                                        rules:
                                                                                          "required",
                                                                                        "data-test-id":
                                                                                          "reducao-carencia",
                                                                                        id: "reducaoCarencia",
                                                                                        name: "ReducaoCarencia",
                                                                                        value:
                                                                                          _vm
                                                                                            .form
                                                                                            .reducaoCarencia,
                                                                                        placeholder:
                                                                                          "Clique aqui para selecionar",
                                                                                        label:
                                                                                          "Vai anexar documento que comprove redução de carência? *",
                                                                                        outlined:
                                                                                          "",
                                                                                        "append-icon":
                                                                                          "mdi-chevron-right",
                                                                                      },
                                                                                  },
                                                                                  on
                                                                                )
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      true
                                                                    ),
                                                                }
                                                              ),
                                                              _vm.form.plano.operadora.toLowerCase() ===
                                                              "unimed volta redonda"
                                                                ? _c(
                                                                    "div",
                                                                    [
                                                                      _c(
                                                                        "base-text-field",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              inputmode:
                                                                                "text",
                                                                              rules:
                                                                                _vm
                                                                                  .form
                                                                                  .reducaoCarencia ===
                                                                                "Sim"
                                                                                  ? "required"
                                                                                  : "",
                                                                              id: "operadoraAnterior",
                                                                              name: "Operadora Anterior",
                                                                              label: `Operadora Anterior ${
                                                                                _vm
                                                                                  .form
                                                                                  .reducaoCarencia ===
                                                                                "Sim"
                                                                                  ? "*"
                                                                                  : ""
                                                                              }`,
                                                                              clearable:
                                                                                "",
                                                                              outlined:
                                                                                "",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .form
                                                                                  .operadoraAnterior,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.form,
                                                                                    "operadoraAnterior",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "form.operadoraAnterior",
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "base-text-field",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "mask",
                                                                                rawName:
                                                                                  "v-mask",
                                                                                value:
                                                                                  "###",
                                                                                expression:
                                                                                  "'###'",
                                                                              },
                                                                            ],
                                                                          attrs:
                                                                            {
                                                                              inputmode:
                                                                                "numeric",
                                                                              rules:
                                                                                _vm
                                                                                  .form
                                                                                  .reducaoCarencia ===
                                                                                "Sim"
                                                                                  ? "required"
                                                                                  : "",
                                                                              id: "tempoPlanoEmMesesAnterior",
                                                                              name: "Tempo em meses do plano Anterior",
                                                                              label: `Tempo em meses do plano Anterior ${
                                                                                _vm
                                                                                  .form
                                                                                  .reducaoCarencia ===
                                                                                "Sim"
                                                                                  ? "*"
                                                                                  : ""
                                                                              }`,
                                                                              clearable:
                                                                                "",
                                                                              outlined:
                                                                                "",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .form
                                                                                  .tempoPlanoEmMesesAnterior,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.form,
                                                                                    "tempoPlanoEmMesesAnterior",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "form.tempoPlanoEmMesesAnterior",
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "base-wrapper-dialog-options",
                                                                        {
                                                                          ref: "dialogAcomodacao",
                                                                          attrs:
                                                                            {
                                                                              headerTitle:
                                                                                "Redução de carência",
                                                                              options:
                                                                                [
                                                                                  {
                                                                                    id: "Coletiva",
                                                                                    nome: "Coletiva",
                                                                                    value:
                                                                                      "coletiva",
                                                                                  },
                                                                                  {
                                                                                    id: "Individual",
                                                                                    nome: "Individual",
                                                                                    value:
                                                                                      "individual",
                                                                                  },
                                                                                ],
                                                                              "value-key":
                                                                                "value",
                                                                              "label-key":
                                                                                "nome",
                                                                            },
                                                                          on: {
                                                                            select:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.$set(
                                                                                  _vm.form,
                                                                                  "acomodacaoAnterior",
                                                                                  $event.id
                                                                                )
                                                                              },
                                                                          },
                                                                          scopedSlots:
                                                                            _vm._u(
                                                                              [
                                                                                {
                                                                                  key: "activador",
                                                                                  fn: function ({
                                                                                    on,
                                                                                  }) {
                                                                                    return [
                                                                                      _c(
                                                                                        "base-text-field",
                                                                                        _vm._g(
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                readonly:
                                                                                                  "",
                                                                                                clearable:
                                                                                                  "",
                                                                                                rules:
                                                                                                  _vm
                                                                                                    .form
                                                                                                    .reducaoCarencia ===
                                                                                                  "Sim"
                                                                                                    ? "required"
                                                                                                    : "",
                                                                                                id: "acomodacaoAnterior",
                                                                                                name: "acomodacaoAnterior",
                                                                                                value:
                                                                                                  _vm
                                                                                                    .form
                                                                                                    .acomodacaoAnterior,
                                                                                                placeholder:
                                                                                                  "Clique aqui para selecionar",
                                                                                                label: `Selecione a acomodação do plano anterior ${
                                                                                                  _vm
                                                                                                    .form
                                                                                                    .reducaoCarencia ===
                                                                                                  "Sim"
                                                                                                    ? "*"
                                                                                                    : ""
                                                                                                }`,
                                                                                                outlined:
                                                                                                  "",
                                                                                                "append-icon":
                                                                                                  "mdi-chevron-right",
                                                                                              },
                                                                                            model:
                                                                                              {
                                                                                                value:
                                                                                                  _vm
                                                                                                    .form
                                                                                                    .acomodacaoAnterior,
                                                                                                callback:
                                                                                                  function (
                                                                                                    $$v
                                                                                                  ) {
                                                                                                    _vm.$set(
                                                                                                      _vm.form,
                                                                                                      "acomodacaoAnterior",
                                                                                                      $$v
                                                                                                    )
                                                                                                  },
                                                                                                expression:
                                                                                                  "form.acomodacaoAnterior",
                                                                                              },
                                                                                          },
                                                                                          on
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  },
                                                                                },
                                                                              ],
                                                                              null,
                                                                              true
                                                                            ),
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "base-wrapper-dialog-options",
                                                                        {
                                                                          ref: "dialogAcomodacao",
                                                                          attrs:
                                                                            {
                                                                              headerTitle:
                                                                                "Item de redução de Carência",
                                                                              options:
                                                                                _vm.opcoesCarencia,
                                                                              "value-key":
                                                                                "value",
                                                                              "label-key":
                                                                                "nome",
                                                                            },
                                                                          on: {
                                                                            select:
                                                                              _vm.selectCarencia,
                                                                          },
                                                                          scopedSlots:
                                                                            _vm._u(
                                                                              [
                                                                                {
                                                                                  key: "activador",
                                                                                  fn: function ({
                                                                                    on,
                                                                                  }) {
                                                                                    return [
                                                                                      _c(
                                                                                        "base-text-field",
                                                                                        _vm._g(
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                readonly:
                                                                                                  "",
                                                                                                rules:
                                                                                                  _vm
                                                                                                    .form
                                                                                                    .reducaoCarencia ===
                                                                                                  "Sim"
                                                                                                    ? "required"
                                                                                                    : "",
                                                                                                id: "itemCarenciaAnterior",
                                                                                                name: "itemCarenciaAnterior",
                                                                                                value:
                                                                                                  _vm
                                                                                                    .form
                                                                                                    .itemCarenciaAnterior,
                                                                                                placeholder:
                                                                                                  "Clique aqui para selecionar",
                                                                                                label: `Selecione o item de redução de carência do plano anterior ${
                                                                                                  _vm
                                                                                                    .form
                                                                                                    .reducaoCarencia ===
                                                                                                  "Sim"
                                                                                                    ? "*"
                                                                                                    : ""
                                                                                                }`,
                                                                                                outlined:
                                                                                                  "",
                                                                                                "append-icon":
                                                                                                  "mdi-chevron-right",
                                                                                              },
                                                                                            model:
                                                                                              {
                                                                                                value:
                                                                                                  _vm.textCarencia,
                                                                                                callback:
                                                                                                  function (
                                                                                                    $$v
                                                                                                  ) {
                                                                                                    _vm.textCarencia =
                                                                                                      $$v
                                                                                                  },
                                                                                                expression:
                                                                                                  "textCarencia",
                                                                                              },
                                                                                          },
                                                                                          on
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  },
                                                                                },
                                                                              ],
                                                                              null,
                                                                              true
                                                                            ),
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm.form.plano.operadora.toLowerCase() ===
                                                                "unimed bh" &&
                                                              _vm.form
                                                                .reducaoCarencia ===
                                                                "Sim"
                                                                ? _c(
                                                                    "div",
                                                                    [
                                                                      _c(
                                                                        "base-wrapper-dialog-options",
                                                                        {
                                                                          ref: "dialogAcomodacao",
                                                                          attrs:
                                                                            {
                                                                              headerTitle:
                                                                                "Informe se a operadora anterior era Unimed BH",
                                                                              options:
                                                                                [
                                                                                  {
                                                                                    id: "Sim",
                                                                                    nome: "Sim",
                                                                                    value:
                                                                                      "Sim",
                                                                                  },
                                                                                  {
                                                                                    id: "Não",
                                                                                    nome: "Não",
                                                                                    value:
                                                                                      "Não",
                                                                                  },
                                                                                ],
                                                                              "value-key":
                                                                                "value",
                                                                              "label-key":
                                                                                "nome",
                                                                            },
                                                                          on: {
                                                                            select:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.$set(
                                                                                  _vm.form,
                                                                                  "confirmarOperadoraAnterior",
                                                                                  $event.id
                                                                                )
                                                                              },
                                                                          },
                                                                          scopedSlots:
                                                                            _vm._u(
                                                                              [
                                                                                {
                                                                                  key: "activador",
                                                                                  fn: function ({
                                                                                    on,
                                                                                  }) {
                                                                                    return [
                                                                                      _c(
                                                                                        "base-text-field",
                                                                                        _vm._g(
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                readonly:
                                                                                                  "",
                                                                                                clearable:
                                                                                                  "",
                                                                                                rules:
                                                                                                  _vm
                                                                                                    .form
                                                                                                    .reducaoCarencia ===
                                                                                                  "Sim"
                                                                                                    ? "required"
                                                                                                    : "",
                                                                                                id: "confirmarOperadoraAnterior",
                                                                                                name: "Informe se a operadora anterior era Unimed BH",
                                                                                                value:
                                                                                                  _vm
                                                                                                    .form
                                                                                                    .confirmarOperadoraAnterior,
                                                                                                placeholder:
                                                                                                  "Clique aqui para selecionar",
                                                                                                label: `Informe se a operadora anterior era Unimed BH ${
                                                                                                  _vm
                                                                                                    .form
                                                                                                    .reducaoCarencia ===
                                                                                                  "Sim"
                                                                                                    ? "*"
                                                                                                    : ""
                                                                                                }`,
                                                                                                outlined:
                                                                                                  "",
                                                                                                "append-icon":
                                                                                                  "mdi-chevron-right",
                                                                                              },
                                                                                            model:
                                                                                              {
                                                                                                value:
                                                                                                  _vm
                                                                                                    .form
                                                                                                    .confirmarOperadoraAnterior,
                                                                                                callback:
                                                                                                  function (
                                                                                                    $$v
                                                                                                  ) {
                                                                                                    _vm.$set(
                                                                                                      _vm.form,
                                                                                                      "confirmarOperadoraAnterior",
                                                                                                      $$v
                                                                                                    )
                                                                                                  },
                                                                                                expression:
                                                                                                  "form.confirmarOperadoraAnterior",
                                                                                              },
                                                                                          },
                                                                                          on
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  },
                                                                                },
                                                                              ],
                                                                              null,
                                                                              true
                                                                            ),
                                                                        }
                                                                      ),
                                                                      _vm.form
                                                                        .confirmarOperadoraAnterior ===
                                                                      "Sim"
                                                                        ? _c(
                                                                            "div",
                                                                            [
                                                                              _c(
                                                                                "base-text-field",
                                                                                {
                                                                                  directives:
                                                                                    [
                                                                                      {
                                                                                        name: "mask",
                                                                                        rawName:
                                                                                          "v-mask",
                                                                                        value:
                                                                                          "###################",
                                                                                        expression:
                                                                                          "'###################'",
                                                                                      },
                                                                                    ],
                                                                                  attrs:
                                                                                    {
                                                                                      inputmode:
                                                                                        "numeric",
                                                                                      rules:
                                                                                        _vm
                                                                                          .form
                                                                                          .confirmarOperadoraAnterior ===
                                                                                        "Sim"
                                                                                          ? "required"
                                                                                          : "",
                                                                                      id: "campoCarteirinha",
                                                                                      name: "Número Carteirinha",
                                                                                      label: `Informe o número da carteirinha do plano anterior  ${
                                                                                        _vm
                                                                                          .form
                                                                                          .confirmarOperadoraAnterior ===
                                                                                        "Sim"
                                                                                          ? "*"
                                                                                          : ""
                                                                                      }`,
                                                                                      clearable:
                                                                                        "",
                                                                                      outlined:
                                                                                        "",
                                                                                    },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        _vm
                                                                                          .form
                                                                                          .campoCarteirinha,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            _vm.form,
                                                                                            "campoCarteirinha",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "form.campoCarteirinha",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm.propostaState.plano.operadora.toLowerCase() ===
                                                                "unimed-rio" &&
                                                              _vm.form
                                                                .reducaoCarencia !==
                                                                ""
                                                                ? _c(
                                                                    "div",
                                                                    [
                                                                      _c(
                                                                        "base-wrapper-dialog-options",
                                                                        {
                                                                          ref: "dialogAcomodacao",
                                                                          attrs:
                                                                            {
                                                                              headerTitle:
                                                                                "Possuí plano anterior ? *",
                                                                              options:
                                                                                [
                                                                                  {
                                                                                    id: "Sim",
                                                                                    nome: "Sim",
                                                                                    value:
                                                                                      "Sim",
                                                                                  },
                                                                                  {
                                                                                    id: "Não",
                                                                                    nome: "Não",
                                                                                    value:
                                                                                      "Não",
                                                                                  },
                                                                                ],
                                                                              "value-key":
                                                                                "value",
                                                                              "label-key":
                                                                                "nome",
                                                                            },
                                                                          on: {
                                                                            select:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.$set(
                                                                                  _vm.form,
                                                                                  "possuiPlanoAnteriorReducaoCarencia",
                                                                                  $event.id
                                                                                )
                                                                              },
                                                                          },
                                                                          scopedSlots:
                                                                            _vm._u(
                                                                              [
                                                                                {
                                                                                  key: "activador",
                                                                                  fn: function ({
                                                                                    on,
                                                                                  }) {
                                                                                    return [
                                                                                      _c(
                                                                                        "base-text-field",
                                                                                        _vm._g(
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                readonly:
                                                                                                  "",
                                                                                                clearable:
                                                                                                  "",
                                                                                                rules:
                                                                                                  "required",
                                                                                                id: "possuiPlanoAnteriorReducaoCarencia",
                                                                                                name: "Possuí plano anterior ?",
                                                                                                value:
                                                                                                  _vm
                                                                                                    .form
                                                                                                    .possuiPlanoAnteriorReducaoCarencia,
                                                                                                placeholder:
                                                                                                  "Clique aqui para selecionar",
                                                                                                label:
                                                                                                  "Possuí plano anterior ? *",
                                                                                                outlined:
                                                                                                  "",
                                                                                                "append-icon":
                                                                                                  "mdi-chevron-right",
                                                                                              },
                                                                                            model:
                                                                                              {
                                                                                                value:
                                                                                                  _vm
                                                                                                    .form
                                                                                                    .possuiPlanoAnteriorReducaoCarencia,
                                                                                                callback:
                                                                                                  function (
                                                                                                    $$v
                                                                                                  ) {
                                                                                                    _vm.$set(
                                                                                                      _vm.form,
                                                                                                      "possuiPlanoAnteriorReducaoCarencia",
                                                                                                      $$v
                                                                                                    )
                                                                                                  },
                                                                                                expression:
                                                                                                  "form.possuiPlanoAnteriorReducaoCarencia",
                                                                                              },
                                                                                          },
                                                                                          on
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  },
                                                                                },
                                                                              ],
                                                                              null,
                                                                              true
                                                                            ),
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "base-text-field",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "mask",
                                                                                rawName:
                                                                                  "v-mask",
                                                                                value:
                                                                                  [
                                                                                    "###",
                                                                                  ],
                                                                                expression:
                                                                                  "['###']",
                                                                              },
                                                                            ],
                                                                          attrs:
                                                                            {
                                                                              inputmode:
                                                                                "numeric",
                                                                              rules:
                                                                                _vm
                                                                                  .form
                                                                                  .possuiPlanoAnteriorReducaoCarencia ===
                                                                                "Sim"
                                                                                  ? "required"
                                                                                  : "",
                                                                              id: "tempoPlanoAnteriorReducaoCarencia",
                                                                              name: "Qual o tempo do plano anterior? Informe os meses",
                                                                              label: `Qual o tempo do plano anterior? Informe os meses ${
                                                                                _vm
                                                                                  .form
                                                                                  .possuiPlanoAnteriorReducaoCarencia ===
                                                                                "Sim"
                                                                                  ? "*"
                                                                                  : ""
                                                                              }`,
                                                                              clearable:
                                                                                "",
                                                                              outlined:
                                                                                "",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .form
                                                                                  .tempoPlanoAnteriorReducaoCarencia,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.form,
                                                                                    "tempoPlanoAnteriorReducaoCarencia",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "form.tempoPlanoAnteriorReducaoCarencia",
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "base-text-field",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              inputmode:
                                                                                "text",
                                                                              rules:
                                                                                _vm
                                                                                  .form
                                                                                  .possuiPlanoAnteriorReducaoCarencia ===
                                                                                "Sim"
                                                                                  ? "required"
                                                                                  : "",
                                                                              id: "operadoraPlanoAnteriorReducaoCarencia",
                                                                              name: "Qual Operadora do plano anterior?",
                                                                              label: `Qual Operadora do plano anterior? ${
                                                                                _vm
                                                                                  .form
                                                                                  .possuiPlanoAnteriorReducaoCarencia ===
                                                                                "Sim"
                                                                                  ? "*"
                                                                                  : ""
                                                                              }`,
                                                                              clearable:
                                                                                "",
                                                                              outlined:
                                                                                "",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .form
                                                                                  .operadoraPlanoAnteriorReducaoCarencia,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.form,
                                                                                    "operadoraPlanoAnteriorReducaoCarencia",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "form.operadoraPlanoAnteriorReducaoCarencia",
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "base-text-field",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              inputmode:
                                                                                "text",
                                                                              rules:
                                                                                _vm
                                                                                  .form
                                                                                  .possuiPlanoAnteriorReducaoCarencia ===
                                                                                "Sim"
                                                                                  ? "required"
                                                                                  : "",
                                                                              id: "registroAnsPlanoAnteriorReducaoCarencia",
                                                                              name: "Qual Registro ANS do plano anterior?",
                                                                              label: `Qual Registro ANS do plano anterior? ${
                                                                                _vm
                                                                                  .form
                                                                                  .possuiPlanoAnteriorReducaoCarencia ===
                                                                                "Sim"
                                                                                  ? "*"
                                                                                  : ""
                                                                              }`,
                                                                              clearable:
                                                                                "",
                                                                              outlined:
                                                                                "",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .form
                                                                                  .registroAnsPlanoAnteriorReducaoCarencia,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.form,
                                                                                    "registroAnsPlanoAnteriorReducaoCarencia",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "form.registroAnsPlanoAnteriorReducaoCarencia",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              690809872
                                            ),
                                          })
                                        : _vm._e(),
                                      _vm.exibirDadosComplementares
                                        ? _c("validation-observer", {
                                            ref: "dadosComplementaresOperadora",
                                            attrs: { slim: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({
                                                    valid,
                                                    failed,
                                                  }) {
                                                    return [
                                                      _c(
                                                        "v-expansion-panel",
                                                        [
                                                          _c(
                                                            "v-expansion-panel-header",
                                                            {
                                                              staticClass:
                                                                "primary--text subtitle-1 font-weight-bold pa-0 pt-4 pb-3",
                                                              staticStyle: {
                                                                "min-height":
                                                                  "auto",
                                                                "justify-content":
                                                                  "space-between",
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "actions",
                                                                      fn: function () {
                                                                        return [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    "primary",
                                                                                  size: "30",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "$expand"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      },
                                                                      proxy: true,
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "d-flex align-center",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Dados complementares da operadora "
                                                                  ),
                                                                  _c(
                                                                    "v-spacer"
                                                                  ),
                                                                  valid
                                                                    ? _c(
                                                                        "v-icon",
                                                                        {
                                                                          staticClass:
                                                                            "mr-2",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                "green",
                                                                              size: "25",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-check-circle"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : failed
                                                                    ? _c(
                                                                        "v-icon",
                                                                        {
                                                                          staticClass:
                                                                            "mr-2",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                "red",
                                                                              size: "25",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-alert-circle"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          ),
                                                          _c("v-divider"),
                                                          _c(
                                                            "v-expansion-panel-content",
                                                            {
                                                              staticClass:
                                                                "pt-4",
                                                              attrs: {
                                                                eager: "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "base-text-field",
                                                                {
                                                                  attrs: {
                                                                    "data-test-id":
                                                                      "dadosComplementaresOperadora-matricula",
                                                                    mode: "aggressive",
                                                                    rules: "",
                                                                    id: "matricula",
                                                                    name: "Matrícula funcional",
                                                                    label:
                                                                      "Matrícula funcional",
                                                                    disabled:
                                                                      _vm.desabilitaInput,
                                                                    clearable:
                                                                      "",
                                                                    outlined:
                                                                      "",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .dadosComplementaresOperadora
                                                                        .matricula,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.dadosComplementaresOperadora,
                                                                          "matricula",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "dadosComplementaresOperadora.matricula",
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "base-wrapper-dialog-options",
                                                                {
                                                                  ref: "dialogPatrocinadora",
                                                                  attrs: {
                                                                    headerTitle:
                                                                      "Patrocinadora",
                                                                    options:
                                                                      _vm.patrocinadoraCemigList,
                                                                    "value-key":
                                                                      "id",
                                                                    "label-key":
                                                                      "nome",
                                                                    optionSelected:
                                                                      _vm
                                                                        .dadosComplementaresOperadora
                                                                        .patrocinadora,
                                                                  },
                                                                  on: {
                                                                    select:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          _vm.dadosComplementaresOperadora,
                                                                          "patrocinadora",
                                                                          $event.id
                                                                        )
                                                                      },
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "activador",
                                                                          fn: function ({
                                                                            on,
                                                                          }) {
                                                                            return [
                                                                              _c(
                                                                                "base-text-field",
                                                                                _vm._g(
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        rules:
                                                                                          "required",
                                                                                        readonly:
                                                                                          "",
                                                                                        id: "patrocinadora",
                                                                                        placeholder:
                                                                                          "Clique aqui para selecionar",
                                                                                        "data-test-id":
                                                                                          "dadosComplementaresOperadora-patrocinadora",
                                                                                        name: "Patrocinadora",
                                                                                        value:
                                                                                          _vm
                                                                                            .dadosComplementaresOperadora
                                                                                            .patrocinadora,
                                                                                        label:
                                                                                          "Patrocinadora *",
                                                                                        disabled:
                                                                                          _vm.desabilitaInput,
                                                                                        outlined:
                                                                                          "",
                                                                                        "append-icon":
                                                                                          "mdi-chevron-right",
                                                                                      },
                                                                                  },
                                                                                  on
                                                                                )
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      true
                                                                    ),
                                                                }
                                                              ),
                                                              _c(
                                                                "base-text-field",
                                                                {
                                                                  attrs: {
                                                                    "data-test-id":
                                                                      "dadosComplementaresOperadora-categoria",
                                                                    mode: "aggressive",
                                                                    rules: "",
                                                                    id: "categoria",
                                                                    name: "Categoria",
                                                                    label:
                                                                      "Categoria",
                                                                    disabled:
                                                                      _vm.desabilitaInput,
                                                                    clearable:
                                                                      "",
                                                                    outlined:
                                                                      "",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .dadosComplementaresOperadora
                                                                        .categoria,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.dadosComplementaresOperadora,
                                                                          "categoria",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "dadosComplementaresOperadora.categoria",
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "base-text-field",
                                                                {
                                                                  attrs: {
                                                                    "data-test-id":
                                                                      "dadosComplementaresOperadora-nome-pai",
                                                                    mode: "aggressive",
                                                                    rules:
                                                                      "required|special_characters",
                                                                    id: "nomePai",
                                                                    name: "Nome completo do pai",
                                                                    label:
                                                                      "Nome completo do pai *",
                                                                    disabled:
                                                                      _vm.desabilitaInput,
                                                                    clearable:
                                                                      "",
                                                                    outlined:
                                                                      "",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .dadosComplementaresOperadora
                                                                        .nomePai,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.dadosComplementaresOperadora,
                                                                          "nomePai",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "dadosComplementaresOperadora.nomePai",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              4033518505
                                            ),
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "secundaryBtn--text",
                                      attrs: {
                                        "data-test-id": "salvar-continuar-4",
                                        loading: _vm.loadingSalvar,
                                        large: "",
                                        type: "submit",
                                        elevation: "10",
                                        block: "",
                                        color: "primary",
                                      },
                                    },
                                    [_vm._v("Salvar")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
          _c("modal-confirmation", {
            attrs: {
              show: _vm.showModalDivergenciaUF,
              name: "modalDivergenciaEstado",
              loading: false,
              title: "Divergência de estado nos endereços",
              text: "Apenas é permitido a utilização de endereço comercial na mesma UF do endereço residencial.",
              buttonConfirm: "Ok",
              buttonCancel: "",
              width: 500,
            },
            on: { confirm: _vm.handleOkButtonModalDivergenciaUF },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }