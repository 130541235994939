<template>
  <div>
    <v-dialog :retain-focus="false" no-click-animation v-model="dialog" persistent fullscreen hide-overlay
      transition="dialog-bottom-transition">
      <v-card tile>
        <component v-if="loading" ref="component" :is="pages[step].component" @next="nextStep" @back="backStep"
          @openmenu="menu = true" @atualizarEtapa="atualizarEtapaProposta"></component>
      </v-card>
    </v-dialog>
    <v-navigation-drawer v-model="menu" fixed right temporary width="330">
      <v-card tile dark color="primary">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title text-center pa-3">Contratação</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-card>
      <div class="scrollbar">
        <!-- TODO: Revisar depois que o fluxo estiver 100% -->
        <v-row no-gutters class="pa-3">
          <v-col cols="6">
            <b>Nº do contrato</b> <br />
            {{ dados.nrContrato || "--" }}
          </v-col>
        </v-row>
        <v-row no-gutters class="pa-3">
          <v-col cols="6">
            <b>Valor total</b> <br />
            {{ (dados.plano && dados.plano.valorTotal) | currency }}
          </v-col>
        </v-row>
        <v-divider class="mb-2"></v-divider>
        <v-row no-gutters class="pa-3">
          <v-col cols="6">
            <b>Qtd. beneficiário</b> <br />
            {{ (dados.dependentes && dados.dependentes.length + 1) || 0 }}
          </v-col>
        </v-row>
        <v-row no-gutters class="pa-3">
          <v-col cols="6">
            <b>Operadora</b> <br />
            {{ (dados.planos && dados.planos.nomeExibicaoOperadora) || "--" }}
          </v-col>
        </v-row>
        <v-row no-gutters class="pa-3">
          <v-col cols="6">
            <b>Vigência</b> <br />
            {{ (dados.vigencia && dados.vigencia.dataVigencia) | convertDateOutput }}
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </div>
      <template v-slot:append>
        <v-list tile dark elevation="6" class="pa-0" color="red darken-4" style="border-radius: 0">
          <v-list-item link class="pt-2 pb-2" @click="leave">
            <v-list-item-icon>
              <v-icon>mdi-exit-to-app</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="subtitle-2 mt-1">Voltar para home</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PreventRealodOnEditing from "@/mixins/preventReloadOnEditing";
import TheTimelineSteps from "@/components/TheTimelineSteps.vue";
import DadosTitular from "./DadosTitular.vue";
import Planos from "./Planos/index.vue"
import DadosResponsavelLegal from "./DadosResponsavelLegal.vue"
import DadosDependentes from "./DadosDependentes/index.vue"
import DadosPortabilidade from "./DadosPortabilidade.vue"
import DadosPessoais from "./DadosPessoais.vue"
import DadosProdutosOpcionais from "./ProdutosOpcionais/index.vue"
import DocumentosTitulares from "./DocumentosTitulares/index.vue"
import empresaService from "@/services/empresaService";
import filters from '@/filters';
import GerenciadorDeStep from "./gerenciadorEtapa";


export default {
  components: {
    TheTimelineSteps,
  },
  mixins: [PreventRealodOnEditing],
  data() {
    return {
      pages: [
        { nome: "Dados Pessoais", component: DadosPessoais }, // STEP 6
        { nome: "Planos", component: Planos }, // STEP 7
        { nome: "Dados Titular", component: DadosTitular }, // STEP 8
        { nome: "Dados Responsavel Legal", component: DadosResponsavelLegal }, // STEP 9
        { nome: "Dados Dependentes", component: DadosDependentes }, // STEP 10
        { nome: "Dados Portabilidade", component: DadosPortabilidade }, // STEP 11
        { nome: "Dados Produtos Opcionais", component: DadosProdutosOpcionais }, // STEP 12
        { nome: "Documentos", component: DocumentosTitulares }, // STEP 13
      ],
      step: 0,
      currentStep: 0,
      dialog: true,
      menu: false,
      dados: {},
      loading: false,
    };
  },
  watch: {
    step(newValue) {
      if (newValue < this.currentStep) return;
      this.currentStep = newValue;
    },
    PMEState: {
      immediate: true,
      handler: "setPME",
      deep: true,
    },
  },
  computed: {
    ...mapState({
      PMEState: (state) => state.PME,
      propostaState: (state) => state.cadastroProposta.proposta,
      dadosTitularState: (state) => state.cadastroProposta.titular,
    }),
  },
  async created() {
    if (this.$route.params.guidPME) {
      const propostaPME = await empresaService.buscarInformacoesTelasCadastro(this.$route.params.guidPME)
      this.setDadosPME(propostaPME)
      if (this.$route.params.guid) {
        const propostaAdesaoPME = await empresaService.buscarDadosPropostasAdesao(this.$route.params.guid)
        this.setDadosPropostaAdesaoPME(propostaAdesaoPME)
      } else {
        this.resetDadosProposta();
      }
      this.setDadosPortabilidade(this.PMEState.corretora.portabilidade)
      this.setVigencia({
        dataVigencia: this.PMEState.vigencia
      });
      this.setDadosPagamento({
        forma: this.PMEState.financeiro.formaPagamento,
      });
      this.setValorTaxaAngariacao(this.PMEState.financeiro.porcentagemTaxaAngariacao);

      if (this.propostaState.etapa) {
        this.redirecionarParaTela(this.propostaState.etapa);
      }
    }
    this.loading = true;
  },
  methods: {
    ...mapActions({
      setDadosPME: "PME/setDadosPME",
      setDadosProposta: 'cadastroProposta/setDadosProposta',
      setDadosPortabilidade: 'cadastroProposta/setDadosPortabilidade',
      setDadosSumula: 'cadastroProposta/setDadosSumula',
      setDadosAdministradora: 'cadastroProposta/setDadosAdministradora',
      setDadosTitular: 'cadastroProposta/setDadosTitular',
      setDadosDependentes: 'cadastroProposta/setDadosDependentes',
      setCorretora: 'cadastroProposta/setCorretora',
      setBlnContinuar: 'cadastroProposta/setBlnContinuar',
      setOperadora: 'cadastroProposta/setOperadora',
      setPlano: 'cadastroProposta/setPlano',
      setVigencia: 'cadastroProposta/setVigencia',
      setDadosPagamento: 'cadastroProposta/setDadosPagamento',
      setDadosReembolso: 'cadastroProposta/setDadosReembolso',
      setResponsavelLegal: 'cadastroProposta/setResponsavelLegal',
      setDadosProfissionais: 'cadastroProposta/setDadosProfissionais',
      setDadosFiliacao: "cadastroProposta/setDadosFiliacao",
      setPendencias: 'cadastroProposta/setPendencias',
      setAdendo: 'cadastroProposta/setAdendo',
      setTipoTaxaAngariacao: 'cadastroProposta/setTipoTaxaAngariacao',
      setValorTaxaAngariacao: 'cadastroProposta/setValorTaxaAngariacao',
      setIdFilial: 'cadastroProposta/setIdFilial',
      resetDadosProposta: 'cadastroProposta/resetDadosProposta',
      setProdutosOpcionais: 'cadastroProposta/setProdutosOpcionais',
      setCorretoraRepasse: "cadastroProposta/setCorretoraRepasse",
    }),
    setDadosPropostaAdesaoPME(propostaAdesaoPME) {
      this.setDadosTitular({
        ...propostaAdesaoPME.titular,
        ...{
          telefoneCelular: this.celularTitular(propostaAdesaoPME) && this.celularTitular(propostaAdesaoPME).numero,
        },
        ...{ tipoTelefoneOpcional: this.telefoneOpcional(propostaAdesaoPME) && this.telefoneOpcional(propostaAdesaoPME).tipo },
        ...{ telefoneOpcional: this.telefoneOpcional(propostaAdesaoPME) && this.telefoneOpcional(propostaAdesaoPME).numero },
        ...{ cep: this.enderecoResidencial(propostaAdesaoPME) && this.enderecoResidencial(propostaAdesaoPME).cep },
        enderecos: this.tratarEnderecos(propostaAdesaoPME),
      })
        this.setPlano(propostaAdesaoPME.plano)
        this.setCorretora(propostaAdesaoPME.corretora)
        if (propostaAdesaoPME.dadosProfissionais) {
          this.setDadosProfissionais(propostaAdesaoPME.dadosProfissionais);
        }
        this.setDadosFiliacao(propostaAdesaoPME.dadosComplementaresFiliacao);

        const deps = propostaAdesaoPME.dependentes.map(
          ({ parentesco, ...resto }) => ({
            ...resto,
            parentesco: parentesco && parentesco.nome,
          }),
        );
        if (deps.length > 0) {
          this.setDadosDependentes(deps);
        }
        this.setCorretora({
          corretora: {
            ...propostaAdesaoPME.corretora,
            guidSupervisor:
              propostaAdesaoPME.corretora && propostaAdesaoPME.corretora.supervisor
                ? propostaAdesaoPME.corretora.supervisor.guid
                : null,
          },
        });
        if (this.verificarSetarPlano(propostaAdesaoPME)) {
          this.setOperadora(propostaAdesaoPME.plano.operadora);
          this.setPlano({ ...propostaAdesaoPME.plano });
          this.setProdutosOpcionais([...propostaAdesaoPME.produtosOpcionais]);
        }
        this.setTipoTaxaAngariacao(propostaAdesaoPME.tipoTaxaAngariacao);
        this.setIdFilial(propostaAdesaoPME.idFilial);
        this.setResponsavelLegal(propostaAdesaoPME.responsavelLegal);
        this.setDadosPortabilidade(propostaAdesaoPME.possuiPortabilidade);
        this.setDadosSumula({ possuiSumula: propostaAdesaoPME.possuiSumula, sumulaPlanoAnterior: propostaAdesaoPME.sumulaPlanoAnterior });
        this.setDadosAdministradora(propostaAdesaoPME.administradora);
        this.setCorretoraRepasse(propostaAdesaoPME.corretoraRepasse);
        this.setDadosProposta({
          ...propostaAdesaoPME,
          valorTotalProposta: propostaAdesaoPME.valorTotalProposta,
          nrProposta: propostaAdesaoPME.nrProposta,
          guid: propostaAdesaoPME.guid,
          status: propostaAdesaoPME.status,
          etapa: propostaAdesaoPME.etapa.id,
          idPlanoSinf: propostaAdesaoPME.idPlanoSinf,
        });
    },
    telefoneOpcional(proposta) {
      if (
        !(
          proposta
          && proposta.titular
          && proposta.titular.telefones
        )
      ) { return ""; }
      return proposta.titular.telefones.find(
        ({ preferencial }) => !preferencial,
      );
    },
    enderecoResidencial(proposta) {
      if (
        !(
          proposta
          && proposta.titular
          && proposta.titular.enderecos
        )
      ) { return ""; }
      return proposta.titular.enderecos.find(
        ({ tipo }) => tipo === "ENDERECO_RESIDENCIAL",
      );
    },
    celularTitular(proposta) {
      if (
        !(
          proposta
          && proposta.titular
          && proposta.titular.telefones
        )
      ) { return ""; }
      return proposta.titular.telefones.find(
        (i) => i.tipo === "CELULAR" && i.preferencial === true,
      );
    },
    verificarSetarPlano(proposta) {
      return (
        proposta
        && proposta.plano
        && Object.keys(proposta.plano).length
      );
    },
    tratarEnderecos(proposta) {
      const enderecos = [];
      if (
        proposta
        && proposta.titular
        && proposta.titular.enderecos
      ) {
        proposta.titular.enderecos.forEach((e) => {
          const item = enderecos.find((ae) => e.cep === ae.cep);
          if (!item) {
            enderecos.push({
              bairro: e.bairro,
              cep: e.cep,
              cidade: e.cidade,
              complemento: e.complemento,
              estado: e.estado,
              logradouro: e.logradouro,
              numero: e.numero,
              tipo: [e.tipo],
            });
          } else {
            item.tipo.push(e.tipo);
          }
        });
      }
      return enderecos;
    },
    setPME() {
      this.dados = {
        ...this.PMEState,
      };
    },
    redirecionarParaTela(etapaAtual) {
      const idade = filters.getAge(new Date(this.dadosTitularState.dataNascimento));
      const possuiPlanosOpcionais = this.propostaState.plano && this.propostaState.plano.possuiPlanosOpcionais;
      const possuiPortabilidade = this.propostaState.possuiPortabilidade;
      const gerenciador = new GerenciadorDeStep(idade, possuiPlanosOpcionais, possuiPortabilidade);
      const steps = gerenciador.retornarSteps();
      this.pages = this.pages.filter(page => steps.includes(page.nome));
      this.step = gerenciador.retornarTelaComBaseEtapaAtual(etapaAtual);
    },
    atualizarEtapaProposta() {
      const idade = filters.getAge(new Date(this.dadosTitularState.dataNascimento));
      const possuiPlanosOpcionais = this.propostaState.plano && this.propostaState.plano.possuiPlanosOpcionais;
      const possuiPortabilidade = this.propostaState.possuiPortabilidade;
      const gerenciador = new GerenciadorDeStep(idade, possuiPlanosOpcionais, possuiPortabilidade);
      const steps = gerenciador.retornarSteps();
      this.pages = this.pages.filter(page => steps.includes(page.nome));
      const telaAtual = this.pages[this.step].nome;
      const proximaEtapa = gerenciador.retornaProximaEtapa(telaAtual, this.propostaState.etapa);
      if (proximaEtapa) {
        this.setDadosProposta({
          ...this.propostaState,
          etapa: proximaEtapa,
        });
      }
    },
    nextStep() {
      const idade = filters.getAge(new Date(this.dadosTitularState.dataNascimento));
      const possuiPlanosOpcionais = this.propostaState.plano && this.propostaState.plano.possuiPlanosOpcionais;
      const possuiPortabilidade = this.propostaState.possuiPortabilidade;
      const gerenciador = new GerenciadorDeStep(idade, possuiPlanosOpcionais, possuiPortabilidade);
      const steps = gerenciador.retornarSteps();
      this.pages = this.pages.filter(page => steps.includes(page.nome));
      this.step++;
    },
    backStep() {
      if (this.step > 0) this.step--;
      else this.$router.go(-1);
    },
    leave() {
      this.menu = false;
      this.$router.push("/");
    },
  },
};
</script>

<style scoped lang="scss">
.v-dialog__content.v-dialog__content--active {
  z-index: 6 !important;
}

.scrollbar {
  width: 100%;
  height: calc(100% - 150px);
  position: fixed;
  overflow-y: scroll;
}
</style>